@use '../breakpoints';
@use '../colors';

section#main-politique {
    width: 100%;
    height: 100%;    
    padding: 8em;
    display: flex;
    flex-direction: column;
    background-color: colors.$primary-white;
    align-items: center;

    .main-title{
        width: 70%;
        height: 280px;

        h1{
            font-size: 80px;
            line-height: 1.1;
            letter-spacing: 6px;
            // font-family: factor-a-regular, "Arial";
            font-family:"factor-a-light";
            font-feature-settings: "ss01";
        }
    }

    .main-intro{
        width: 70%;
        height: 200px;

        h1{
            font-size: 50px;
            padding-bottom: 20px;
            font-family: factor-a-regular, "Arial";
        }

        p{
            font-size: 22px;
            font-family: neue-haas-unica-pro;
        }
    }

    .section-title{
        width: 70%;
        height: 100%;
        padding-top: 2em;

        h1{
            font-size: 50px;
            font-family: factor-a-regular, "Arial";
        }

        .section-subtitle{
            padding-top: 20px;
            padding-bottom: 20px;

            p{
                font-size: 22px;
                line-height: 1.5;
                font-family: neue-haas-unica-pro;
            }
        }

        p{
            font-size: 22px;
            line-height: 1.5;
            font-family: neue-haas-unica-pro;
        }

        ul{
            padding-bottom: 20px;

            li{
                margin-left: 1em;
                line-height: 1.5;

                font-size: 22px;
                font-family: neue-haas-unica-pro;
            }
        }
    }
}




@media (max-width: breakpoints.$breakpoint-tablet) {

    section#main-politique {
        padding: 0;//
        padding-left: 1.5em;//
        padding-right: 1.5em;//
        padding-top: 3em; //
        padding-bottom: 3em; //


        .main-title{
            width: 100%;//
            height: 150px; //
    
            h1{
                font-size: 45px; //
                letter-spacing: 2px;//
            }
        }
    
        .main-intro{
            width: 100%; //
            height: 160px; //
    
            h1{
                font-size: 30px; //
            }
    
            p{
                font-size: 16px; //
            }
        }
    
        .section-title{
            width: 100%; //
            height: 100%;
    
            h1{
                font-size: 30px; //
            }
    
            .section-subtitle{
    
                p{
                    font-size: 16px; //
                }
            }
    
            p{
                font-size: 16px; //
            }
    
            ul{    
                li{
                    font-size: 16px; //
                }
            }
        }
    }

}
  

@media (max-width: breakpoints.$breakpoint-mobile) {

    section#main-politique {
        padding: 0;//
        padding-left: 1.5em;//
        padding-right: 1.5em;//
        padding-top: 3em; //
        padding-bottom: 3em; //


        .main-title{
            width: 100%;//
            height: 150px; //
    
            h1{
                font-size: 45px; //
                letter-spacing: 2px;//
            }
        }
    
        .main-intro{
            width: 100%; //
            height: 250px;

            h1{
                font-size: 30px; //
            }
    
            p{
                font-size: 16px; //
            }
        }
    
        .section-title{
    
            width: 100%; //
            height: 100%;
    
            h1{
                font-size: 30px; //
            }
    
            .section-subtitle{
    
                p{
                    font-size: 16px; //
                }
            }
    
            p{
                font-size: 16px; //
            }
    
            ul{    
                li{
                    font-size: 16px; //
                }
            }
        }
    }
}