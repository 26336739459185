.shadow-box-container {
  overflow: hidden;
  position: absolute;
  width: 360px;
  height: 500px;
  display: flex;
  flex-direction: row;

  img {
    width: 20px;
    height: 20px;
  }

   &:first-of-type {
     left: -232px;
     top: -230px;
   }

  &:last-of-type {
    bottom: -440px;
    right: -70px;
  }
}

.rectangle-container{
  position: absolute;
  cursor: pointer;
  opacity: 0;

  &:first-of-type {
    bottom: -20px;
    right: -20%;

    p {
      &::after{
      }
    }
  }

  &:last-of-type {
    left: -60%;
    top: -50px;

    img {

    }

    p {
      &::after{

      }
    }
  }

  &.fade {
    animation-duration: 5s;
    animation-fill-mode: both;
    -webkit-animation-duration: 5s;
    -webkit-animation-duration: 5s;
    -webkit-animation-fill-mode: both;
    animation-name: fadeInUp;
    animation-iteration-count: infinite;

  }
}


@keyframes fadeInUp {
  0% {
    transform: translate3d(0, 100px, 0);
    opacity: 0;
  }

  50% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, -100px,0);
    opacity: 0;
  }
}

@keyframes changeTextLeft {
  0% {
    content: "Salaire Concurentiel";
  }

  34% {
    content: "Programme de télémédecine";
  }

  50% {
    content: "Bonus de performance";
  }

  66% {
    content: "Télétravail";
  }

  83% {
    content: "Matériel et allocation";
  }

  100% {
    content: "Salaire Concurentiel";
  }
}

@keyframes changeImageLeft {
  0% {
    content:url("../../../public/img/carrieres/cash.png");
  }

  34% {
    content:url("../../../public/img/carrieres/telemed.png");
  }

  50% {
    content:url("../../../public/img/carrieres/cash_envelope.png");
  }

  66% {
    content:url("../../../public/img/carrieres/laptop.png");
  }

  83% {
    content:url("../../../public/img/carrieres/chair.png");
  }

  100% {
    content:url("../../../public/img/carrieres/diplome.png");
  }
}


@keyframes changeTextRight {
  0% {
    content: "Test1";
    background-color: red;
  }

  50% {
    content: "Test2";
    background-color: green;
  }
}




