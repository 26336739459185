@use '../colors';
@use '../breakpoints';

#page-carriere {
  position: relative;



  section {
    min-height: 300px;
  }

  .indice {
    font-size: 1em;
    height: 100%;
    vertical-align: top;
    padding-left: 0.3em;
  }

  .msg {
    z-index: 3;
    background-color: colors.$secondary-light-blue;
    height: 200px;
    width: 200px;
    transform: rotate(13.51deg);
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;


    > p {
      display: block;
      width: 70%;
      font-family: factor-a-medium;
      font-size: 1.5em;
    }

    &:nth-of-type(1) {
      left: 60%;
    }

    &:nth-of-type(2) {
      p{
        left: 5%;
        background-color: colors.$secondary-dark-green;
        width: 300px;
        animation-delay: 3s;
        bottom: 0;
      }

    }

    &:hover, p:hover {
      animation-play-state: paused;
    }

  }

  .carrieres-carousel-interface {
    display: none;
    justify-content: space-between;
    align-items: center;
    margin: 2em 2em 0 2em;

    .carrieres-carousel-button {
      font-family: 'factor-a-medium';
      font-weight: bold;
      font-size: 1.5em;
    }

    .carrieres-carousel-arrow {
      cursor: pointer;

      &:nth-child(1) {
        text-align: left;
      }

      &:nth-child(3) {
        text-align: right;

      }
    }
  }

  font-family: factor-a-regular;

  .slick-arrow {
    display: none !important;
  }

  .slick-slide {
    height: 550px;

    > div {
      display: flex;
      justify-content: center;
    }
  }


  h2, p, h3 {
    font-family: factor-a-regular;
  }

  h2 {
    font-size: 3.5em;
    text-align: center;
  }

  section {
    padding: 3em 0;
    margin: 2em;

    &:nth-of-type(1) {
      padding-top: 1em;
      border-top: none;
    }

  }

  section#carrieres-top {
    display: flex;
    padding-bottom: 50px;
    padding-left: 50px;
    justify-content: space-between;
    max-width: 2000px;
    margin-right: 2em;

    .link {
      margin-right: 0;
      &::after {
        margin: 0;
        width: 100%;
      }
    }

    p {
      &:first-of-type{
        margin-right: 1em;
      }

      .indice {
        display: inline-block;
      }

      a {
        display: inline-block;
      }

      span {
        display: flex;
      }
    }

    > div {
      min-width: 120px;

      &:nth-child(1) {
        margin: 100px 1em 1em 0;

        h2 {
          font-size: 5em;
          text-align: left;
          line-height: 88px;
          font-family:"factor-a-light";

          span {
            display: block;
            font-family:"factor-a-light";
            font-feature-settings: "ss01";
          }
        }

        > div {
          display: flex;
          margin-top: 3em;
          flex-wrap: wrap;
        }
      }

      &:nth-child(2) {
        position: relative;
        height: 100%;
        margin-top: 50px;
        margin-right: 100px;

        .rectangle-container {
          border: #111111 1.5px solid;
          background-color: #fff;

          position: absolute;
          min-width: 300px;
          z-index: 3;
          padding: 2em 1.5em;
          height: 90px;
          width: 350px;

          display: flex;
          flex-direction: row;
          justify-content: left;
          align-items: center;

          > img {
            width: 90px;
            height: 90px;
            display: block;
            margin: 0;

          }


          &:first-child {
            box-shadow: colors.$secondary-light-green 10px 10px 0px -1px, rgb(0 0 0) 10px 10px;
          }

          &:last-child {
            box-shadow: colors.$secondary-dark-purple 10px 10px 0px -1px, rgb(0 0 0) 10px 10px;
          }
        }

        > img {
          height: 448px;
          width: 358px;
        }

        p {
          font-size: 1.5em;
          margin-left: 1em;
          font-family: factor-a-regular;

          span {
            display: block;
          }
        }
      }
    }
  }
}

section#carrieres-msg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-top: 1.5px solid black;
  padding-left: 2em;
  padding-right: 4em;


  .msg {
    margin: 10px;

    p {
      padding: 0.5em;
      width: 100%;
      font-size: 30px;
      line-height: 27px;
    }
  }

  h2 {
    width: 60%;
    text-align: left;
    padding: 0.1em;
  }

  > div {
    background-color: colors.$secondary-light-blue;
  }
}

section#carrieres-extra {
  border-top: 1.5px solid black;
  padding-bottom: 0;

  h2 {
    text-align: center;
    margin-bottom: 2em;
    margin-top: 1em;
    font-family:"factor-a-light";
    font-feature-settings: "ss01";
  }

  .link {
    margin-right: 0;

    &::after {
      width: 40%;
      margin: auto;
    }
  }

  .carousel-card {
    width: 350px;
    height: 530px;;
    position: relative;
    text-align: center;
    border: 1px solid black;
    background-color: colors.$primary-white;
    box-shadow: colors.$secondary-light-purple 15px 15px 0px -1px, rgb(0 0 0) 15px 15px;

    img.author-avatar {
      width: 100px !important;
      height: 100px !important;
      display: inline-block;
      position: absolute;
      right: -90px;
      top: 20px;
    }

    .carousel-card-footer {
      height: 100px;

      h3 {
        height: 50px;
        position: relative;

      }
    }

    .fade-in-underline {
      margin: auto;
      line-height: 1em;
      width: 90%;
    }

    img {
      margin: 0;
      padding: 0;
      height: 430px;
      width: 348px;
    }

    > div {
      height: 420px;
    }

    .slick-slide .slick-active .slick-current {
      width: 90%;
    }

    .carousel-card-content {
      &::-webkit-scrollbar-track
      {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #d71818;
      }
      &::-webkit-scrollbar
      {
        width: 120px;
        background-color: #c92e2e;
      }
      &::-webkit-scrollbar-thumb
      {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #555;
      }

      border-bottom: 1px solid black;
      width: 100%;
      height: 450px;
      overflow: scroll;
      padding: 0 1em;
      text-align: justify;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }


      h3 {
        font-size: 2em;
        padding: 0.5em 0;
        width: 70%;
        text-align: left;
        font-family: 'factor-a-bold';
        position: relative;
      }
    }

    .extra {
      position: absolute;
    }

    #carrieres-extra-face-1 {
      bottom: 80px;
      left: 30px;
      width: 100px;
      height: 100px;
    }

    #carrieres-extra-face-2 {
      bottom: 80px;
      right: 20px;
      width: 100px;
      height: 100px;
    }

    a {
      cursor: pointer;
      padding-top: 10px;
    }

    > div, a {
      width: 100%;
      display: block;
      text-align: center;
      line-height: 2em;
      padding-bottom: 10px;
    }

    h3 {
      margin-top: 10px;
      min-height: 20px;
      font-size: 24px;
      padding-top: 20px;
    }

    h4, h5 {
      font-size: 1.2em;
    }

    h5 {
      opacity: 0.5;
      margin-bottom: 1em;
    }

  }
}

section#carrieres-avantages {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0;
  cursor: pointer;
  border-top: unset;

  h2 {
   &.avantages {
     display: none;
   }
  }

  .msg {
    display: flex;
    justify-content: center;
    &.avantages {
      display: none;
      background-color: colors.$secondary-dark-green;

    }
  }

  h2 {
    height: 95%;
    font-size: 2.5em;
    border-left: unset;
    text-align: left;
    margin: 1em 0;
    padding-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    width: 400px;
    flex-wrap: wrap;

    .msg {
      font-size: 0.41em;
      background-color: colors.$secondary-dark-green;

    }
  }

  li {
    width: 12%;
    border-right: 1.5px solid black;
    border-top: 1.5px solid black;
    border-bottom: 1.5px solid black;
    height: 700px;
  }

  .avantages-row {
    font-family: 'factor-a-bold';
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 3em;
    border-right: 1.5px solid black;
    border-top: 1.5px solid black;
    border-bottom: 1.5px solid black;


    &:nth-last-child(1) {
      border-right: none;
    }

    > div {

      &:nth-child(1) {
        border-radius: 100%;
        border: 1.5px solid black;
        padding: 3em;
        margin-bottom: 50px;

      }

      &:nth-child(2) {
        height: 210px;

        p {
          line-height: 30px;
        }
      }

      h3 {
        font-size: 2em;
        margin-bottom: 0.5em;
      }
    }

    img {
      height: 115px;
      width: 100px;
    }
  }

  .avantages-carousel {
    display: none;
  }

  .avantages-container {
    margin: 0;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .carrieres-carousel-interface {
    margin-bottom: 2em;
  }
}

section#carrieres-jobs {
  padding-top: 0;
  .indice {
    font-size: 0.5em;
  }

  h2 {
    margin-bottom: 0.5em;
  }

  ul {
    list-style-type: none;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;

      border-top: 2px solid black;
      padding-top: 2em;
      padding-bottom: 2em;
      font-family: factor-a-medium;

      &:last-of-type{
        border-bottom: 2px solid black;
      }


      div:nth-child(1) {
        width: 70%;
        font-size: 2.5em;

      }

      span:nth-child(2) {
        padding: 0.5em;
        font-size: 2.5em;
        font-size: 1.3em;
        text-align: right;
        width: 15%;
        opacity: 0.5;
        padding-right: 2em;
      }

      div:nth-child(3) {
        width: 15%;

        justify-self: flex-end;
        display: flex;
        align-items: center;
        font-size: 1em;

        span {
          padding: 1em;
          opacity: 0.5;
          order: 1
        }
      }

      .main-button {
        margin: 0;
        min-width: 250px;
        height: 60px;

      }
    }
  }
}


@media (max-width: breakpoints.$breakpoint-tablet) {
  #page-carriere {

    .slick-arrow {
      display: none !important;
    }

    .slick-slide {

      > div {
        display: flex;
        justify-content: center;
      }
    }

    .slick-list {
      height: 450px;
    }

    .carrieres-carousel-interface {
      display: flex !important;
    }

    section#carrieres-top {
      margin: 2em;
      margin-bottom: 0;
      padding: 0;
      padding-top: 2em;

      h2 {
        width: 100%;
      }

      div:nth-child(1) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        margin: 0;

        h2 {
          line-height: 1em;
          font-size: 3em;

        }

        > div {

          a {
            margin-bottom: 1em;

          }
        }
      }

      > div:nth-child(2) {
        margin-top: 1em;
      }

    }

    section#carrieres-msg {
      padding: 1em;

      .msg {
        p {
          text-align: center;
          padding: 0.1em;
        }
      }

    }

    section#carrieres-extra {
      padding-top: 0;
      height: 800px;
      border-bottom: 2px solid black;

      h2 {
        text-align: center;
        margin-bottom: 1em;
      }

      .carousel-card {
        width: 315px;
        height: 430px;


        h3 {
          padding-top: 0;
        }

        .carousel-card-content {
          height: 370px;
          h3 {
            display: flex;
          }
        }

        .carousel-card-footer {
          h3 {
            height: 25px;
          }
        }

        > div {
          height: 350px;
        }

        img {
          width: 313px;
          height: 350px;
        }
      }
    }

    section#carrieres-avantages {
      display: block;
      flex-direction: column;
      height: 100%;
      width: unset;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-top: 100px;

      h2 {
        &.avantages {
          display: block;
          padding: 0.5em 1em;
          margin-bottom: 0;
        }
      }

      a {
        display: none;
      }

      .msg {
        &.avantages {
          transform: rotate(0deg);
          width: 100%;
          display: block;
          position: absolute;
          background-color: unset;
          display: flex;
          justify-content: center;
          padding-right: 5%;
          position: absolute;
          top: -180px;
          p {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 170px;
            left: 30%;
            top: -218px;
            height: 170px;
            transform: rotate(-20deg);
          }
        }
      }



      .carousel-card-content {
        height: 700px;
        border-bottom: 1.5px solid black;
      }

      .carrieres-carousel-interface {
        display: flex;

        .carrieres-carousel-arrow {
          margin: 0 1em;
        }
      }

      .slick-slider {
        height: 650px;
      }
      .slick-list {
        height:700px;
      }
      .slick-track {
        margin-top: 40px;
      }

      h2 {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }

      .horizontal-container {
        display: none;
      }

      .avantages-carousel {
        display: block;
        margin: 0 0.5em;
        height: 760px;
        border-top: 2px solid black;
        border-bottom: 2px solid black;

        .slick-list {
          height: 600px;
        }
      }

      .avantages-row {
        border: none;
        height: 570px;
        width: 100%;

        div:nth-child(2) {
          height: unset;
        }
      }
    }

    section#carrieres-jobs {



      ul {
        li {
          flex-wrap: wrap;
          margin: 1em 1em 2em 1em;
          padding-left: 0;

          div:nth-child(1) {
            margin-bottom: 0.5em;
            text-align: left;
            width: 100%;
          }

          span:nth-child(2) {
            text-align: left;
            order: -1;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: breakpoints.$breakpoint-mobile) {
  #page-carriere {

    section {
      padding: 1em 0;
      margin: 0;
    }

    .slick-slide {
      height: 400px;
      width: 500px;
    }

    section {
      h2 {
        font-size: 2em;
      }
    }

    section#carrieres-msg {
      padding: 1em;
      padding-bottom: 0;
      margin-bottom: 30px;

      h2 {
        margin-top: 1em;
        width: 100%;
      }

      .msg {
        margin-top: 1,75em;
        margin-bottom: 2em;
        height: 170px;
        width: 280px;
        transform: rotate(-20.75deg);

      }
    }

    section#carrieres-top {
      flex-wrap: wrap;

      > div:nth-child(1) {
        h2 {
          font-size: 2.8em;
        }
      }

      > div:nth-child(2) {
        height: 100%;

        img {
          position: relative;
          width: 358px;
          margin: auto;
        }

        .rectangle-container {
          display: none;
        }
      }
    }


    section#carrieres-extra {
      h2 {
        text-align: left;
        padding: 0 1em;
      }

      .carousel-card {
        width: 300px;
        height: 430px;


        img.author-avatar {
          right: -70px;
          top: -10px;
        }


        .carousel-card-content {
          height: 370px;

          h3 {
            width: 80%;
          }
        }

        > div {
          height: 350px;
          width: 290px;
        }

        img {
          width: 298px;
          height: 360px;
        }
      }
    }
  }
}

@media (max-width: breakpoints.$breakpoint-small) {
  #page-carriere {

    section#carrieres-msg {
      flex-wrap: wrap;
      max-height: 300px;
      justify-content: center;

      .msg {
        width: 160px;
        height: 160px;
      }
    }

    section#carrieres-avantages {
      .msg {
        &.avantages {
          left: 0;
          top: -210px;
        }
      }
    }

    section#carrieres-extra {
      padding-top: 70px;
      height: 850px;
    }

    section#carrieres-top {
      > div {

        &:nth-child(1) {
          width: 100%;
          margin: auto;
        }

        &:nth-child(2) {
          > img {
            display: none;
            height: 348px;
            width: 258px;
          }
        }
      }
    }

    section#carrieres-jobs {

      ul {
        li {
          flex-direction: column;
          align-content: flex-start;

          div:nth-child(1) {
            margin-bottom: 0.5em;
            text-align: left;
            width: 100%;
          }

          span:nth-child(2) {
            text-align: left;
            order: -1;
            width: 100%;
          }

          div:nth-child(3) {
            width: 50%;
            align-self: flex-start;
          }
        }
      }

    }
  }
}
