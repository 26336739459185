@use '../breakpoints';
@use '../colors';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    display: block;
}

.footer {
    width: 100%;
    height: 618px;
    max-height: 618px;
    background-color: colors.$secondary-light-purple;
    padding-right: 5em;
    padding-left: 5em;
    padding-top: 4em;
    padding-bottom: 4em;


    .footer-links-container {
        border-bottom: colors.$primary-black 1.5px solid;
        width: 100%;
        height: 82%;
        display: flex;

        .footer-links {
            border-right: colors.$primary-black 1.5px solid;
            width: 60%;
            margin: 0;
            display: flex;
            flex-direction: column;

            .footer-links-img {
                height: 29%;
                display: flex;
                align-items: center;

                img {
                    margin-left: 0;
                    width: 450px;
                }
            }

            .footer-links-title {
                height: 13%;

                p {
                    font-family: neue-haas-unica-pro, 'arial';
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            .footer-links-connexion {
                height: 29%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 25px;

                .footer-links-connexion-intact {
                    width: 180px;
                    margin-right: 30px;

                    a {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        color: colors.$primary-black;
                        padding-top: 6px;
                        padding-bottom: 4px;
                        position: relative;
                    }

                    p {
                        font-family: factor-a-medium, 'arial';
                        font-weight: 500;
                        font-size: 16px;
                        margin-right: 10px;
                    }
                }

                .footer-links-connexion-agp {
                    width: 150px;

                    a {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        color: colors.$primary-black;
                        padding-top: 2px;
                        padding-bottom: 3px;
                        position: relative;
                    }

                    p {
                        font-family: factor-a-medium, 'arial';
                        font-weight: 500;
                        font-size: 16px;
                        margin-right: 8px;
                    }

                }

                .footer-links-animation {
                    a::before {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 1px;
                        bottom: -1px;
                        background-color: colors.$primary-black;
                        transform: scaleX(1);
                        transition: transform 500ms ease;
                    }

                    a:hover::before {
                        transform: scaleX(0);
                    }

                }
            }

            .footer-links-social {
                height: 29%;
                display: flex;
                border-top: colors.$primary-black 1.5px solid;

                .footer-links-social-general {
                    border-right: colors.$primary-black 1.5px solid;
                    width: 33%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a {
                        position: relative;
                        transition: transform 500ms ease;
                    }

                    a:hover {
                        transform: scale(1.5, 1.5);
                    }
                }

                .footer-links-social-in {
                    width: 34%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a {
                        position: relative;
                        transition: transform 500ms ease;
                    }

                    a:hover {
                        transform: scale(1.5, 1.5);
                    }
                }
            }

        }

        .footer-reclamation {
            width: 40%;
            padding-left: 3em;

            .footer-reclamation-title {
                font-family: factor-a-medium, 'arial';
                font-weight: 500;
                font-size: 34px;
                border-bottom: colors.$primary-black 1.5px solid;
                padding-top: 0.4em;
                padding-bottom: 0.4em;
            }

            .footer-reclamation-assureurs {
                display: flex;

                .logo-assureurs {
                    width: 130px;
                    margin-left: 0;
                }

                .telephone-assureurs {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    a {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        height: 50%;
                        color: colors.$primary-black;
                        position: relative;
                    }

                    a::before {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 1px;
                        bottom: -3px;
                        background-color: colors.$primary-black;
                        transform: scaleX(1);
                        transition: transform 500ms ease;
                    }

                    a:hover::before {
                        transform: scaleX(0);
                    }

                    p {
                        font-family: factor-a-medium, 'arial';
                        font-weight: 500;
                        font-size: 16px;
                        margin-right: 12px;
                    }
                }
            }

            .intact {
                border-bottom: colors.$primary-black 1.5px solid;
                padding-top: 1.7em;
                padding-bottom: 1.7em;

            }

            .unique {
                border-bottom: colors.$primary-black 1.5px solid;
                padding-top: 1.75em;
                padding-bottom: 1.75em;
            }

            .pafco {
                padding-top: 2.5em;
                padding-bottom: 2em;
            }

            .telephone-pafco {
                height: 50%;
            }

        }
    }

    .footer-polique-container {
        height: 18%;
        border-bottom: colors.$primary-black 1.5px solid;
        display: flex;
        align-items: center;

        p {
            font-family: factor-a-medium, 'arial';
            font-weight: 500;
            font-size: 16px;
        }

        a {
            text-decoration: none;
            font-family: factor-a-medium, 'arial';
            font-weight: 500;
            font-size: 16px;
            color: colors.$primary-black;
            position: relative;
        }

        a::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 1px;
            bottom: -3px;
            background-color: colors.$primary-black;
            transform: scaleX(0);
            transition: transform 500ms ease;
        }

        a:hover::before {
            transform: scaleX(1);
        }

        .politique-left-title {
            width: 40%;
            display: flex;
            justify-content: flex-start;
        }

        .politique-right-title {
            width: 60%;
            display: flex;
            justify-content: flex-end;
        }

    }

}

.footer-mobile {
    display: none;
    width: 99.8%;
    height: 650px;
    max-height: 650px;
    background-color: colors.$secondary-light-purple;
    padding-top: 4em;
    padding-bottom: 4em;

    .footer-mobile-top {
        height: 55%;
        padding-right: 1em;
        padding-left: 1em;

        .footer-mobile-top-logo {
            width: 350px;
            display: block;
            margin-left: auto;
            margin-right: auto
        }

        .footer-mobile-top-links {
            display: flex;
            flex-direction: column;
            padding-top: 40px;
            padding-left: 15px;

            a {
                display: flex;
                text-decoration: none;
                color: colors.$primary-black;
                align-items: center;
                position: relative;
            }

            a::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 1px;
                bottom: -3px;
                background-color: colors.$primary-black;
                transform: scaleX(1);
                transition: transform 500ms ease;
            }

            a:hover::before {
                transform: scaleX(0);
            }

            p {
                font-size: 24px;
                font-weight: 500;
                font-family: factor-a-medium, 'arial';
            }

            img {
                margin-left: 14px;
            }

            .footer-mobile-top-links-agp {
                width: 215px;
                min-width: 215px;
            }

            .footer-mobile-top-links-reclamation {
                padding-top: 30px;
                width: 265px;
                min-width: 265px;
            }

            .footer-mobile-top-links-intact {
                padding-top: 30px;
                width: 260px;
                min-width: 260px;
            }
        }
    }

    .footer-mobile-middle {
        height: 20%;
        width: 100%;
        margin-top: 25px;
        border-top: solid 1px #111111;
        border-bottom: solid 1px #111111;

        .footer-mobile-middle-links {
            height: 100%;
            display: flex;
            align-items: center;

            a {
                position: relative;
                transition: transform 500ms ease;
            }

            a:hover {
                transform: scale(1.35, 1.35);
            }

            .footer-mobile-middle-links-general {
                border-right: solid 1px #111111;
                width: 33%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .footer-mobile-middle-links-in {
                width: 33%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .footer-mobile-bottom {
        height: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .footer-mobile-bottom-politique {
            height: 40%;
            margin-top: 20px;
            display: flex;
            align-items: center;

            p {
                font-weight: 400;
                font-size: 14px;
            }

            a {
                text-decoration: none;
                font-family: factor-a-medium, 'arial';
                font-weight: 500;
                font-size: 16px;
                color: colors.$primary-black;
                position: relative;
            }

            a::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 1px;
                bottom: -3px;
                background-color: colors.$primary-black;
                transform: scaleX(1);
                transition: transform 500ms ease;
            }

            a:hover::before {
                transform: scaleX(0);
            }
        }

        .footer-mobile-bottom-text {
            height: 60%;

            p {
                font-family: factor-a-medium, 'arial';
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                opacity: 0.6;
                text-align: center;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 10px;
            }
        }
    }
}

@media (max-width: 1050px) {

    .footer {

        .footer-links-container {

            .footer-reclamation {

                .footer-reclamation-assureurs {

                    .telephone-assureurs {

                        p {
                            font-size: 13px;
                        }

                    }

                }
            }
        }
    }
}

@media (max-width: 950px) {

    .footer {
        display: none;
    }

    .footer-mobile {
        display: block;

        .footer-mobile-top {

            .footer-mobile-top-logo {
                width: 500px;
            }

            .footer-mobile-top-links {
                align-items: center;
            }

        }
    }
}

@media (max-width: 600px) {

    .footer {
        display: none;
    }

    .footer-mobile {
        display: block;

        .footer-mobile-top {

            .footer-mobile-top-logo {
                width: 330px;
            }

            .footer-mobile-top-links {
                align-items: flex-start;
            }

        }
    }
}