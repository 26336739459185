@use '../breakpoints';
@use '../colors';

:root {
    --transformation: translate(0px, 0px);
}

section#main-banner {
    width: 100%;
    padding-top: 5em;
    display: flex;
    flex-wrap: wrap;

    .main-title {
        width: 65%;
        height: 770px;
        display: flex;
        align-content: flex-start;
        align-items: center;
        position: relative;
        padding-left: 30px;

        .avatar-blue {
            width: 180px;
            position: absolute;
            z-index: 3;
            bottom: 17%;
            left: 88%;
            animation-name: pop-blue;
            animation-duration: 1.75s;
            animation-timing-function: cubic-bezier(.45, .58, .7, 1.01);
            animation-fill-mode: forwards;
        }

            @keyframes pop-blue {
                0% {
                    opacity: 0%;
                    scale: 0.1;
                }
    
                75% {
                    opacity: 100%;
                    scale: 1.35;
                    transform: rotate(-0.1turn);
                }
    
                100% {
                    opacity: 100%;
                    scale: 1;
                    transform: rotate(-0.075turn);
                }
            }

        .avatar-orange {
            width: 180px;
            position: absolute;
            z-index: 3;
            bottom: 85%;
            left: 34%;
            animation-name: pop-orange;
            animation-duration: 1.5s;
            animation-timing-function: cubic-bezier(.45, .58, .7, 1.01);
            animation-fill-mode: forwards;
        }

        @keyframes pop-orange {
            0% {
                opacity: 0%;
                scale: 0.1;
            }
            75% {
                opacity: 100%;
                scale: 1.35;
                transform: rotate(0turn);
            }

            100% {
                opacity: 100%;
                scale: 1;
                transform: rotate(0.015turn);
            }
        }

        .avatar-green {
            width: 180px;
            position: absolute;
            z-index: 3;
            bottom: 7%;
            left: 7%;
            animation-name: pop-green;
            animation-duration: 2s;
            animation-timing-function: cubic-bezier(.45, .58, .7, 1.01);
            animation-fill-mode: forwards;
        }

        @keyframes pop-green {
            0% {
                opacity: 0%;
                scale: 0.1;
            }

            75% {
                opacity: 100%;
                scale: 1.35;
                transform: rotate(0.2turn);
            }

            100% {
                opacity: 100%;
                scale: 1;
                transform: rotate(0.05turn);
            }
        }

        .title {
            width: 90%;
            height: 650px;
            margin-left: 6em;

            h1 {
                font-size: 10em;
                line-height: 1;
                letter-spacing: 4px;
                font-feature-settings: "ss01";
            }
        }
    }

    .main-circle {
        width: 35%;
        height: 770px;
        z-index: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        // border-bottom: colors.$primary-black 1.5px solid;
        
        .split {
            width: 1.5px;
            height: 770px;
            position: absolute;
            background-color: colors.$primary-black;
        }

        .circle {
            width: 350px;
            height: 350px;
            position: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            transform: var(--transformation);
            transition: all 0.8s;
            transition-timing-function: linear;
            cursor: pointer;
            background-color: colors.$secondary-light-green;

            .circle-title {
                text-align: center;
                transform: rotate(13deg);
                transition: all 0.8s;
                transition-timing-function: linear;

                h2 {
                    font-size: 2.5em;
                    line-height: 1;
                    font-weight: bold;
                }
            }
        }

        .circle:hover {
            width: 320px;
            height: 320px;

            .circle-title { 
                transform: rotate(0deg);
            }

        }
    }

    .break-line{
        width:100%;
        border-bottom: colors.$primary-black 1.5px solid;
        margin-left: 5em;
    }
   
}


@media (max-width: breakpoints.$breakpoint-tablet) {

    section#main-banner {
        width: 100%;
        padding-top: 0;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-left: 1.5em;
        padding-right: 1.5em;

        .main-title {
            width: 100%;
            height: 350px;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            border-bottom: 0;
            padding-left: 0;

            .avatar-blue {
                bottom: -70%;
                left: 73%;
            }
    
            .avatar-orange {
                bottom: 30%;
                left: 70%;
            }
    
            .avatar-green {
                bottom: -25%;
                left: 7%;
            }

            .title {
                display: flex;
                align-content: center;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: wrap;
                flex-direction: column;
                width: 100%;
                height: 260px;
                margin-left: 0;
    
                h1 {
                    font-size: 80px;
                }
            }
        }
    
        .main-circle {
            width: 100%;
            height: 320px;
            z-index: 1;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 0;
            
            .split {
                display: none;
            }
    
            .circle {
                width: 290px;
                height: 290px;
                z-index: 100;
                position: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                transform: rotate(13deg); 
                transition: all 0.3s ease-in-out 0s;
                cursor: pointer;
                background-color: colors.$secondary-light-green;
    
                .circle-title {
                    text-align: center;
    
                    h2 {
                        font-size: 2.3em;
                        line-height: 1;
                        font-weight: bold;
                    }
                }
            }
    
            .circle:hover {
                width: 340px;
                height: 340px;
                transform: rotate(0deg);
                transition: all 0.3s ease-in-out 0s;
            }
        }
        
        .break-line{
            display: none;
        }
    }  
}
  

@media (max-width: breakpoints.$breakpoint-mobile) {

    section#main-banner {
        width: 100%;
        padding-top: 0;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-left: 1.5em;
        padding-right: 1.5em;

        .main-title {
            width: 100%;
            height: 240px;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            border-bottom: 0;
            padding-left: 0;

            .avatar-blue {
                display: none;
                }

            .avatar-orange {
                display: none;
            }

            .avatar-green {
                display: none;
            }

            .title {
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: 90%;
                height: 200px;
                margin-left: 0;
                flex-direction: unset;
    
                h1 {
                    font-size: 47px;
                    font-family: factor-a-regular, arial;
                }
            }
        }
    
        .main-circle {
            width: 100%;
            height: 200px;
            z-index: 1;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 0;
            
            .split {
                display: none;
            }
    
            .circle {
                width: 210px;
                height: 210px;
                z-index: 100;
                position: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                transform: rotate(0deg); 
                transition: all 0.3s ease-in-out 0s;
                cursor: pointer;
                background-color: colors.$secondary-light-green;
    
                .circle-title {
                    text-align: center;
    
                    h2 {
                        font-size: 1.5em;
                        line-height: 1;
                        font-weight: bold;
                    }
                }
            }
    
            .circle:hover {
                width: 260px;
                height: 260px;
                transform: rotate(0deg);
                transition: all 0.3s ease-in-out 0s;
            }
        }

        .break-line{
            display: none;
        }
       
    }    
}