$global-font-stack:  -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

// FACTOR-A
@font-face {
    font-family: factor-a-bold;
    src: local('factor-a-bold'), url(fonts/FactorA/Bold/Factor-A-Bold.ttf) format('truetype');
}

@font-face {
    font-family: factor-a-light;
    src: local('factor-a-light'), url(fonts/FactorA/Light/Factor-A-Light.ttf) format('truetype');
}

@font-face {
    font-family: factor-a-medium;
    src: local('factor-a-medium'), url(fonts/FactorA/Medium/FactorA-Medium.ttf) format('truetype');
}

@font-face {
    font-family: factor-a-regular;
    src: local('factor-a-regular'), url(fonts/FactorA/Regular/FactorA-Regular.ttf) format('truetype');
}

// NEUE HAAS UNICA
@font-face {
    font-family: neue-haas-unica-pro;
    src: local('neue-haas-unica-pro'), url(fonts/NeueHaasUnica/Alternative-files/Neue-Haas-Unica-Pro.ttf) format('truetype');
}

@font-face {
    font-family: neue-haas-unica-w1g;
    src: local('neue-haas-unica-w1g'), url(fonts/NeueHaasUnica/Alternative-files/neue-haas-unica-w1g.ttf) format('truetype');
}

@font-face {
    font-family: neue-haas-unica-w1g-itl;
    src: local('neue-haas-unica-w1g-itl'), url(fonts/NeueHaasUnica/neue-haas-unica-w1g-Italic.ttf) format('truetype');
}

@font-face {
    font-family: neue-haas-unica-w1g-itl-md;
    src: local('eue-haas-unica-w1g-itl-md'), url(fonts/NeueHaasUnica/neue-haas-unica-w1g-MediumIt.ttf) format('truetype');
}

