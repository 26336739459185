@use '../breakpoints';
@use '../colors';
@import url("https://netdna.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.css");


section#schedules{
    width: 100%;
    height: 100%;
    padding-right: 5em;
    padding-left: 5em;
    display: flex;
    align-items: center;
    align-content: center;
    background-color: colors.$primary-white;    

    .left-schedule {
        width: 40%;
        height: 920px;
        padding-top: 80px;
        border-top: colors.$primary-black 1.5px solid;

        img{
            display: block;
            width: 500px;
        }
    }

    .right-schedule {
        
        width: 60%;
        height: 920px;
        display: flex;
        padding-left: 5em;
        padding-top: 50px;
        border-left: colors.$primary-black 1.5px solid;
        border-top: colors.$primary-black 1.5px solid;

        .schedules{
            width: 100%;
            height: 400px;
            border-top: colors.$primary-black 1.5px solid;
                           
            ul {
                height: 364px;
                list-style: none;
                
                li {
                    position: relative;
                    padding-left: 1.5em;
                    border-bottom: colors.$primary-black 1.5px solid;

                    h2{
                        height: 120px;
                        font-size: 35px;
                        display: flex;
                        flex-wrap: wrap;
                        align-content: center;
                        justify-content: space-between;
                    }

                    i {
                        right: 4%;
                        margin-top: 3.5em;
                        position: absolute;
                        transform: translate(-6px, 0);

                        &:before, &:after {
                            width: 3px;
                            height: 25px;
                            content: "";
                            position: absolute;
                            background-color: colors.$primary-black;
                        }

                        &:before {
                            transform: translate(-15px, 0) rotate(45deg);
                        }

                        &:after {
                            transform: translate(2px, 0) rotate(-45deg);
                        }
                    }

                    input[type=checkbox] {
                        position: absolute;
                        cursor: pointer;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        opacity: 0;
                    }

                    input[type=checkbox]:checked ~ p {
                        margin-top: 0;
                        max-height: 0;
                        opacity: 0;
                        transform: translate(0, 50%);
                    }

                    input[type=checkbox]:checked ~ i:before {
                        transform: translate(3px, 0) rotate(45deg);
                    }

                    input[type=checkbox]:checked ~ i:after {
                        transform: translate(-13px, 0) rotate(-45deg);
                    }

                    .transition, p, ul li i:before, ul li i:after {
                        transition: all 0.15s ease-in-out;
                    }
                }
            }
        }
    }
}



@media (max-width: 1425px) {

    section#schedules{
        padding-right: 1.5em;
        padding-left: 1.5em;
        padding-top: 1.5em; 
        padding-bottom: 0; 

        flex-wrap: wrap;

        .left-schedule {
            width: 100%; 
            height: 650px;
            border-bottom: 0;
            border-top: 0;
            padding-top: 0; 
            justify-content: center;
            display: flex;

            img{
                width: 400px;

            }
        }
    
        .right-schedule {
            width: 100%; 
            height: 800px; 
            padding-left: 0; 
            padding-top: 1.5em; 
            border-left: 0; 
            border-top: 0;
    
            .schedules{
                                
                ul {
                    
                    li {
                        padding-left: 0;

                        h2{
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: breakpoints.$breakpoint-mobile) {

    section#schedules{

        .left-schedule {
            width: 100%; 
            height: 520px;
            border-bottom: 0;
            border-top: 0;
            padding-top: 0; 

            img{
                width: 100%;
                height: 100%;
            }
        }
    
        .right-schedule {
            width: 100%; 
            height: 800px; 
            padding-left: 0; 
            padding-top: 1.5em; 
            border-left: 0; 
            border-top: 0;
    
            .schedules{
                                
                ul {
                    
                    li {
                        padding-left: 0;

                        h2{
                            font-size: 32px;
                        }
                    }
                }
            }
        }
    }
}
  