@use '../breakpoints';
@use '../colors';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section#main-form-particulier {
    height: 800px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;


    .main-form-particulier-title {
        height: 35%;
        display: flex;
        align-items: center;

        .main-form-particulier-title-texte {
            
            width: 55%;
            height: 170px;
            font-family: factor-a-regular, 'arial';
            font-size: 2.8em;
            padding-left: 2em;
            display: flex;
            align-items: center;
            font-feature-settings: "ss01";

        }

        .main-form-particulier-title-avatar {
            width: 45%;
            height: 170px;
            padding-left: 100px;
            display: flex;
            align-items: center;

            .speech-bubble {
                    margin-top: 1em;
                    width: 50%;
                    height: 115px;
                    font-size: 20px;
                    border-radius: 15px;
                    display: flex;
                    position: relative;
                    align-items: center;
                    align-content: flex-start;
                    background-color: colors.$secondary-light-green;
            
                    p {
                        font-size: 21px;
                        margin-left: 20px;
                        line-height: 30px;
                        font-family: factor-a-regular;
                        color: colors.$primary-black;
                    }
                }
            
            .speech-bubble:after {
                width: 0;
                top: 50%;
                z-index: 1;
                right: -21px;
                margin-top: -10px;
                border-width: 10px 0 10px 21px;
                content: '';
                display: block;
                position: absolute;
                border-style: solid;
                border-color: transparent colors.$secondary-light-green;
            }

            .avatar {
                width: 50%;
                height: 170px;
                display: flex;
                align-items: center;
                align-content: center;
                padding-left: 30px;

                img {
                    width: 170px;
                    height: auto;
                }
            }
        }
    }

    .main-form-particulier-choices {
        height: 50%;
        margin-bottom: 3em;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .container{

            .container-circle{

                width: 300px;
                height: 300px;
                border-radius: 50%;
                cursor: pointer;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                border: colors.$primary-black 1.5px solid;

                img.img-checked{
                    display: none;
                }
                img#vehicule{
                    width: 90%;
                }
                img#habitation{
                    width: 75%;
                }
                img#bateau{
                    width: 87%;
                }
                img#biens_locataire{
                    width: 85%;
                }
                img#recreatif{
                    width: 80%;
                }
            }

            .container-circle-checked{
                width: 300px;
                height: 300px;
                border-radius: 50%;
                cursor: pointer;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                border: colors.$primary-black 1.5px dashed;
                position: relative;

                img.img-checked{
                    width: 50px;
                    top: 5px;
                    left: 208px;
                    position: absolute;
                }

                img#vehicule{
                    width: 90%;
                }
                img#habitation{
                    width: 75%;
                }
                img#bateau{
                    width: 87%;
                }
                img#biens_locataire{
                    width: 85%;
                }
                img#recreatif{
                    width: 80%;
                }
            }

            .container-title{

                h3{
                    padding-top: 20px;
                    text-align: center;
                    font-family: factor-a-medium, arial;
                    font-size: 1.5em;
                }
            }
        }
    }

    .main-form-particulier-select {
        height: 25%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

}

@media screen and (max-width: 1525px) {
    section#main-form-particulier {
        min-height: 1125px;

        .main-form-particulier-title {
            height: 25%;

            .main-form-particulier-title-texte {
                width: 50%;
                font-size: 2em;
            }

            .main-form-particulier-title-avatar {
                padding-left: 10px;

                .speech-bubble {  
                    p {
                        font-size: 15px;
                    }
                }
            }
        }

        .main-form-particulier-choices {
            height: 60%;
            flex-wrap: wrap;
        }

        .main-form-particulier-select {
            height: 10%;
        }

    }
}


@media screen and (max-width: 910px) {
    section#main-form-particulier {
        width: 100%;
        height: 100%;

        
        .main-form-particulier-title {
            margin-bottom: 3em;
            display: flex;
            flex-direction: column;

            .main-form-particulier-title-texte {
                width: 100%;
                font-size: 2.2em;
                margin-right: 0.5em;
                margin-left: 2em;
                max-height: 150px;
            }

            .main-form-particulier-title-avatar {
                width: 100%;
                padding-left: 17%;
            
                .speech-bubble {
                    p {
                        font-size: 0.87em;
                    }
                }

                .avatar {
    
                    img {
                        width: 150px;
                        height: auto;
                    }
                }
            }

        }

        .main-form-particulier-choices {
            min-height: 300px;
            flex-direction: row;
            flex-wrap: wrap; //

            .container{

                .container-circle{
                    width: 180px; //
                    height: 180px; //    
                }
    
                .container-circle-checked{
                    width: 180px; //
                    height: 180px; //
    
                    img.img-checked{
                        left: 140px; //
                    }
                }
    
                .container-title{
    
                    h3{
                        font-size: 1.2em; //
                    }
                }
            }

        }

        .main-form-particulier-select {
            margin-top: 1em;
            margin-bottom: 3em;
        }
    }
}

@media screen and (max-width: 750px) {

    section#main-form-particulier {
        width: 100%;
        height: 100%;

        .main-form-particulier-title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .main-form-particulier-title-texte {
                width: 100%;
                height: 50%;
                margin-left: 0;
                padding-left: 1em;
                font-size: 1.8em;
                margin-top: 1.5em;
            }

            .main-form-particulier-title-avatar {
                padding-left: 1.5em;
                width: 100%;
                height: 50%;

                .speech-bubble {
                    width: 250px;
                    height: 95px;
                    p {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }

                .avatar {
                    height: 127px;

                    img {
                        width: 100px;

                    }
                }
            }

        }

        .main-form-particulier-choices {
            min-height: 480px;
            flex-direction: row;
            flex-wrap: wrap; //

            .container{

                .container-circle{
                    width: 150px; //
                    height: 150px; //    
                }
    
                .container-circle-checked{
                    width: 150px; //
                    height: 150px; //
    
                    img.img-checked{
                        left: 110px; //
                        width: 35px;
                        top: 5px;
                    }
                }
    
                .container-title{
    
                    h3{
                        font-size: 1.2em; //
                        padding-bottom: 25px; //
                    }
                }
            }
        }

        .main-form-particulier-select {
            margin-top: 0;
        }
    }
}

