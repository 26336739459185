@use '../breakpoints';
@use '../colors';

.file-field{
    display: block;
    border: 2px dotted black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    cursor: pointer;



    input {
        display: none;
    }

    label {
        h1{
            min-height: unset !important;
            font-weight: normal;
            font-family: factor-a-medium !important;
        }
    }

}


.main-text-fields {
    width: 100%;
    height: auto;

    input[type=date]::-webkit-inner-spin-button,
    input[type=date]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

    label {
        h1{
            margin-top: 10px;
            min-height: 40px;
            font-size: 17px;
            font-family: factor-a-bold;
            color: colors.$primary-black;

            .required{
                color:#f47628;
            }

            .description{
                color: rgb(148, 148, 148);
            }
        }
    }

    input[type=file] {
        &.error {
            &::before{
                background-size: 30px;
                background-position: 99%;
                height: 200px;
                width: 200px;
                display: block;
                background-repeat: no-repeat;
                background-image: url('../../../public/img/red_error.png');
            }
        }
    }

    input[type=text], input[type=tel], input[type=number], input[type=email], input[type=date], select {
        width: 100%;
        height: 55px;
        outline: none;
        box-sizing: border-box;
        border: 2.5px solid colors.$primary-black;
        padding-left: 10px;
        font-size: 21px;
        font-family: factor-a-regular;

        &.error  {
            display: flex;
            background-size: 30px;
            background-position: 99%;
            border: 2.5px solid #f47628;
            background-repeat: no-repeat;
            background-image: url('../../../public/img/red_error.png');
        }

        &.success {
            display: flex;
            background-size: 30px;
            background-position: 99%;
            background-repeat: no-repeat;
            background-image: url('../../../public/img/blue_check.png');
        }
    }

    input[type=text]:focus, input[type=tel]:focus, input[type=date]:focus {
        border: 2.5px solid colors.$primary-black;
    }

    select{
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("../../../public/img/fields/arrow.png");
        background-repeat: no-repeat;
        background-size: 30px;
        background-position-x: 97%;
        background-position-y: 20px;
        height: 100%;
        border-radius: 2px;
        margin-right: 2rem;
        padding: 1rem;
        padding-right: 2rem;


        &:hover {
            opacity: 0.5;
        }
    }

    .error-message{
        margin-top: 5px;
        font-size: 15px;
        color:#f47628;
    }
    .success-message{
        margin-top: 5px;
        font-size: 15px;
        color:#42AAF5;
    }
}

.custom-checkbox{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;    

    label {
        width: 90%;
        padding-top: 4px;
        padding-left: 20px;

        p{
            min-height: 40px;
            font-size: 20px;
            font-family: factor-a-regular;
            color: colors.$primary-black;

            .required{
                color:#f47628;
            }

            .description{
                color: colors.$primary-black;
            }
        }
    }

    input[type="checkbox"] {
        margin: 0;
        width: 30px;
        height: 30px;
        font: inherit;
        display: grid;
        appearance: none;
        color: currentColor;
        place-content: center;
        -webkit-appearance: none;
        transform: translateY(-0.075em);
        background-color: var(--form-background);
        border: colors.$primary-black 2.5px solid;
    }
    
    input[type="checkbox"]::before {
        content: "";
        width: 18px;
        height: 18px;
        transform: scale(0);
        background-color: CanvasText;
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    
    input[type="checkbox"]:checked::before {
        transform: scale(1);
    }
}

.amf-field{
    a {
        color: #000;
        position: relative;
        text-decoration: none;
        font-family: factor-a-medium;
    }
    
    a:hover {
        color: #000;
    }

    a::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        bottom: -1px;
        left: 0;
        background-color: #000;
        transform: scaleX(1);
        transition: transform 550ms ease;
    }

    a:hover::before {
        transform: scaleX(0);
    }
}


@media (max-width: breakpoints.$breakpoint-tablet) {


}


@media (max-width: breakpoints.$breakpoint-mobile) {



}
