$primary-black : #111111;
$primary-white : #FCFCFC;
$secondary-dark-purple : #5554DB;
$secondary-light-blue: #42AAF5;
$secondary-dark-green : #057F79;
$secondary-light-green : #CAF456;
$secondary-dark-orange : #F47628;
$secondary-light-purple : #C7A0F9;

.secondary-light-blue{
  color: $secondary-light-blue;
}

.secondary-dark-orange{
  color: $secondary-dark-orange;
}

.secondary-dark-purple{
  color: $secondary-dark-purple;
}

.secondary-light-green{
  color: $secondary-light-green;
}
