@use 'fonts';
@use 'colors';
@use 'animations';
@use 'breakpoints';
@use 'Navbars/navbar';
@use 'Footers/footer';
@use 'Buttons/button';
@use 'Buttons/drawer';
@use 'Banners/banner';
@use 'Banners/particulier';
@use 'Banners/entreprise';
@use 'Banners/insurance';
@use 'Banners/reclamation';
@use 'Banners/localisation';
@use 'Banners/confirmation';
@use 'Banners/politique';
@use 'Datepicker/datepicker';
@use 'Forms/choices-entreprise';
@use 'Forms/choices-particulier';
@use 'Forms/concours-form';
@use 'Forms/dates-particulier';
@use 'Forms/rendez-vous';
@use 'Forms/soumission';
@use 'Carrieres/carrieres';
@use 'Carrieres/shadow-box';
@use 'Carrieres/carrieres-fiches';
@use 'Carrieres/carriereres-form';
@use 'Fields/fields';
@use 'Modal/modal';
@use 'Schedules/schedule';
@use 'Pages/particuliers';
@use 'Pages/entreprises';
@use 'Pages/reclamations';
@use 'Pages/nous-joindre';
@use 'Pages/politique-de-confidentialite';


html {
    box-sizing: border-box;
}

h1{
    font-family: factor-a-light, arial;
}

a,h2{
    font-family: factor-a-bold, arial;
}

*, *::before, *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

.large-screen-wrapper {
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}

.main-container {
  position: relative;
  z-index: 1;
  background: white;
  box-shadow: 0px 15px 10px -15px #111;
}

.main-footer {
  position: sticky;
  bottom: 0;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.nodrag {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}



.wrapper {
  width: 100%;
  height: 100%;
  padding-left: 5em;
  padding-right: 5em;
}

.link {
  margin-right: 2em;
  text-decoration: none;
  font-size: 1.5em;
  font-family: factor-a-medium;
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.link::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  bottom: -10px;
  left: 0;
  background-color: #000;
  transform: scaleX(1);
  transition: transform 550ms ease;
}

.link:hover::after {
  transform: scaleX(0);
}

hidden {
  display: none;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}


.horizontal-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

  .space-holder {
    position: relative;
    width: 100%;
  }

  .sticky {
    position: sticky;
    top: 0;
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .horizontal {
    position: absolute;
    height: 100%;
    will-change: transform;
  }

  .avantages-rows {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    padding-top: 120px;
  }

  .avantages-row {
    position: relative;
    height: 300px;
    width: 500px;
    flex-shrink: 0;
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }

  .redcolorbanner{
    background-color: red;
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {

    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 0;
      padding-right: 0;
    }

  }

  @media (max-width: 1050px) {
    .main-container {
      overflow: hidden;
    }

    .main-footer {
      overflow: hidden;
    }
  }

