@use '../breakpoints';
@use '../colors';


:root {
    --backgroundColor: "#FCFCFC";
}

section#main-insurance {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;

    .choice {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: flex-start;

        .subtitle {
            margin-bottom: 2em;

            h2 {
                font-size: 1.5em;
                font-family: factor-a-medium, arial;
            }
        }

        .title {
            margin-bottom: 1em;

            h1{
                font-size: 4em;
                font-family: factor-a-regular, arial;
                font-feature-settings: "ss01";

            }
        }

        .button-choice {
            width: 62%;
            display: flex;
            align-content: center;
            align-items: center;

            .spec-button {
                width: 100%;
                height: 80px;
                margin: 1em;
                margin-left: 0;
                display: flex;
                align-content: center;
                align-items: center;
                text-decoration: none;
            }
        }
    }

    .type {
        width: 25%;
        height: 900px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        // background: linear-gradient(to left, #FCFCFC 50%, var(--backgroundColor) 50%) right;
        // background-size: 200%;
        // transition: 1s ease-out;
        background-image: linear-gradient(var(--backgroundColor), var(--backgroundColor));
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 0% 100%;
        transition: background-size 1s, color 1s;
        border-left: colors.$primary-black 1.5px solid;

        .container {
            display: flex;
            flex-direction: column;
            
            .title {  
                text-align: center;
                margin-bottom: 3em;
                
                h2{
                    font-family: factor-a-medium, "Arial";
                }
            }
    
            .type-circle {
                width: 350px;
                height: 350px;
                cursor: pointer;
                border-radius: 50%;
                border: colors.$primary-black 1.5px solid;
                background-color: colors.$primary-white;
                display: flex;
                align-items: flex-end ;
                align-content: center;
                justify-content: center;
                overflow: hidden;

                img#car {
                    width: 120%;
                    padding-right: 5em;
                    padding-bottom: 3em;
                }

                img#house {
                    width: 100%;
                }
            }

            .type-subtitle {
                margin-top: 6em;
                text-align: center;

                h1 {
                    font-size: 2.5em;
                    font-family: factor-a-regular;
                }
            }

            .ask-soum {
                margin-top: 3em;
                text-align: center;
                cursor: pointer;
                
                a {
                    color: #000;
                    position: relative;
                    text-decoration: none;
                    font-size: 1.2em;
                    font-family: factor-a-bold;
                }
              
                a:hover {
                    color: #000;
                }

                a::before {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 2px;
                    bottom: -10px;
                    left: 0;
                    background-color: #000;
                    transform: scaleX(1);
                    transition: transform 550ms ease;
                }

                a:hover::before {
                    transform: scaleX(0);
                }
            }
        }
        
        &:active, &:hover {            
            .type-circle{
                border: colors.$primary-black 1.5px dashed;
            }
        }

    }

    .type:hover {
        background-size: 100% 100%;
        // color: #fff;
    }

}


@media (max-width: breakpoints.$breakpoint-tablet) {

    section#main-insurance {
        padding-top: 50px;
        padding-bottom: 50px;

        .choice {
            width: 100%;
            
            .subtitle {
                width: 90%;

                border-top: colors.$primary-black 1.5px solid;
                padding-top: 50px;
                h2 {
                    font-size: 1em;
                }
            }
    
            .title {
                margin-bottom: 1em;
    
                h1{
                    font-size: 2.5em;
                }
            }
    
            .button-choice {
                width: 62%;
                display: flex;
                align-content: center;
                align-items: center;
                flex-direction: column;
    
                .spec-button {
                    margin: 0.5em;
                }
            }
        }
        .type {
            display: none;
        }
    }

}
  

@media (max-width: breakpoints.$breakpoint-mobile) {

    section#main-insurance {
        padding-top: 50px;
        padding-left: 1.5em;
        .choice {
            width: 100%;
            
            .subtitle {
                width: 90%;
    
                h2 {
                    font-size: 1em;
                }
            }
    
            .title {
                margin-bottom: 1.5em;
    
                h1{
                    font-size: 2.3em;
                }
            }
    
            .button-choice {
                width: 62%;
                display: flex;
                align-content: center;
                align-items: center;
                flex-direction: column;
    
                .spec-button {
                    margin: 0.5em;
                }
            }
        }
        .type {
            display: none;
        }
    }
}