@use '../breakpoints';
@use '../colors';

section#main-reclamation {
    min-height: 850px;    
    background-color: colors.$primary-white;
    display: flex;


    .left-container {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .left-container-avatar {
            height: 40%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .speech-bubble {
                margin-top: 1em;
                width: 36%;
                height: 100px;
                font-size: 20px;
                border-radius: 15px;
                display: flex;
                position: relative;
                align-items: center;
                align-content: flex-start;
                background-color: colors.$secondary-light-green;
        
                p {
                    font-size: 21px;
                    margin-left: 20px;
                    line-height: 30px;
                    font-family: factor-a-regular;
                    color: colors.$primary-black;
                }
            }

            .speech-bubble:after {
                width: 0;
                top: 50%;
                z-index: 1;
                right: -21px;
                margin-top: -10px;
                border-width: 10px 0 10px 21px;
                content: '';
                display: block;
                position: absolute;
                border-style: solid;
                border-color: transparent colors.$secondary-light-green;
            }

            .avatar {
                margin-left: 2em;
                img {
                    width: 150px;
                    height: auto;
                }
            }
        }

        .left-container-title {  
            padding-bottom: 2em;

            h1 {
                font-size: 6em;
                font-family: factor-a-regular, 'arial';
                font-feature-settings: "ss01";
            }
        }

        .left-container-subtitle {

            .desktop {
                font-weight: 400;
                font-size: 1.45em;
                line-height: 24px;
                font-family: factor-a-regular, 'arial';
            }

            .mobile {
                display: none;
            }
        }
    }

    .right-container {
        width: 41.7%;
        border-left: 1px solid colors.$primary-black;

        .logo {
            display: flex;
            width: 225px;
        }

        .right-container-intact {
            display: flex;
            align-items: center;
            height: 33%;
            border-bottom: 1px solid colors.$primary-black;
            padding-left: 3em;

        }

        .right-container-unique {
            display: flex;
            align-items: center;
            height: 33%;
            border-bottom: 1px solid colors.$primary-black;
            padding-left: 3em;
        }

        .right-container-pafco {
            display: flex;
            align-items: center;
            height: 33%;
            padding-left: 3em;
        }

        .links-connexion-agp {
            padding-left: 4em;
        
                a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: colors.$primary-black;
                    padding-top: 2px;
                    padding-bottom: 3px;
                    position: relative;
                }
        
                p {
                    font-family: factor-a-medium, 'arial';
                    font-weight: 500;
                    font-size: 26px;
                }
        
                img {
                    display: block;
                    margin-left: 1em;
                    width: 30px;

                }
            }

        .links-animation {
            a::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 1px;
                bottom: -1px;
                background-color: colors.$primary-black;
                transform: scaleX(1);
                transition: transform 500ms ease;
            }

            a:hover::before {
                transform: scaleX(0);
            }
        }
    }
}


@media (max-width: 1410px) {
    section#main-reclamation {

        .left-container {
            justify-content: center;

            .left-container-avatar {
                display: none;
            }

            .left-container-title {

                h1 {
                    font-size: 70px;
                }
            }

            .left-container-subtitle {
                font-size: 12px;
            }
        }

        .right-container {
            
            .logo {
                    display: flex;
                    align-items: center;
                    width: 225px;
                    padding-bottom: 30px;
                }
            
            .right-container-intact {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 33%;
                border-bottom: 1px solid colors.$primary-black;
                padding-left: 0;
        
            }
        
            .right-container-unique {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                height: 33%;
                border-bottom: 1px solid colors.$primary-black;
                padding-left: 0;
            }
        
            .right-container-pafco {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                height: 33%;
                padding-left: 0;
            }

            .links-connexion-agp {
                padding-right: 0;
                padding-left: 1em;
    
                a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: colors.$primary-black;
                    padding-top: 2px;
                    padding-bottom: 3px;
                    position: relative;
                }
    
                p {
                    font-family: factor-a-medium, 'arial';
                    font-weight: 500;
                    font-size: 26px;
                }
    
                img {
                    display: block;
                    margin-left: 1em;
                    width: 30px;
    
                }
            }
        }
    }
}

@media (max-width: breakpoints.$breakpoint-mobile) {

    section#main-reclamation {
        flex-direction: column;
        align-items: center;
        min-height: 700px;

        .left-container-avatar {
            display: none;
        }

        .left-container {
            width: 100%;
            padding-bottom: 10px;

            .left-container-title {
                    padding-bottom: 10px;
                    padding-top: 75px;
            
                    h1 {
                        font-size: 3.5em;
                        font-family: factor-a-regular, 'arial';
                        font-feature-settings: "ss01";
                    }
                }

            .left-container-subtitle {
                
                .desktop {
                    display: none;
                }

                .mobile {
                    display: block;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 50px;
                    font-size: 1em;
                    line-height: 24px;
                    font-family: factor-a-regular, 'arial';
                }
        }

        }

        .right-container {
            width: 90%;
            border-left: none;
            min-height: 400px;
            display: flex;
            flex-direction: column;

            .logo {
                    width: 150px;
                    margin-left: 0;
                    padding: 0;
                }
            
                .right-container-intact {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-bottom: 1px solid colors.$primary-black;
                    border-top: 1px solid colors.$primary-black;
                    padding-top: 30px;
                    padding-bottom: 30px;
            
                }
            
                .right-container-unique {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    border-bottom: 1px solid colors.$primary-black;
                    padding-top: 30px;
                    padding-bottom: 30px
                }
            
                .right-container-pafco {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    border-bottom: 1px solid colors.$primary-black;
                    padding-top: 40px;
                    padding-bottom: 40px;
    
                }

                .links-connexion-agp {
                    padding-right: 0;

                    a {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        color: colors.$primary-black;
                        position: relative;
                    }

                    p {
                        font-family: factor-a-medium, 'arial';
                        font-weight: 500;
                        font-size: 15px;
                    }

                    img {
                        display: block;
                        width: 25px;

                    }
                }

                .links-animation {
                    a::before {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 1px;
                        bottom: -1px;
                        background-color: colors.$primary-black;
                        transform: scaleX(1);
                        transition: transform 500ms ease;
                    }

                    a:hover::before {
                        transform: scaleX(0);
                    }
                }
        }
    }
}

@media (max-width: 375px) {

    section#main-reclamation {

        .right-container {

            .links-connexion-agp {
                padding-left: 1em;
            }

        }
    }

}