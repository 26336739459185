.falling{
  animation: drop 15s ease forwards;
  animation-iteration-count:infinite;
  position: absolute;
  opacity: 0;
}

@keyframes drop{
  0%{bottom: 0; opacity: 0}
  10%{opacity: 100%}
  50%{opacity: 100%}
  100%{bottom: 100%; opacity: 0}
}
