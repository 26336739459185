@use '../colors';
@import url("https://netdna.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.css");

.drawer {
  h3{
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  img {
    width: 20px;
    height: 10px;
    cursor: pointer;
  }

  .drawer-content{
    height: 0;
    overflow: hidden;
  }

  &.open {
    .drawer-content{
      animation: open ease 2s forwards;
    }

    img{
      transform: rotate(180deg)
    }
  }



  input[type=checkbox] {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }

  .drawer-content{

    p{
      margin-bottom: 1em;
    }
  }

  input[type=checkbox]:checked {
    &~.drawer-content {
      margin-top: 0;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      height: 100%;
    }
  }

  input[type=checkbox]:checked ~ i:before {
    transform: translate(3px, 0) rotate(45deg);
  }

  input[type=checkbox]:checked ~ i:after {
    transform: translate(-13px, 0) rotate(-45deg);
  }

  @keyframes flipdown {
    0% {
      opacity: 0;
      transform-origin: top center;
      transform: rotate(0deg);
    }
    100% {
      opacity: 1;
      transform-origin: top center;
      transform: rotate(360deg);
    }
  }
  @keyframes close {
    0% {
      height: 100%;
    }
    100% {
      height: 0;
    }
  }

  @keyframes open {
    0% {
      height: 0;
    }
    100% {
      height: 100%;
    }
  }



}
