@use '../breakpoints';
@use '../colors';

:root {
    --showHamMenu: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    display: block;
}

a {
    color: inherit;
    text-decoration: none;
}

.ham-container {
    display: none;
}

.main-container-navbar {
    background-color: colors.$secondary-light-purple;
    padding-left: 3em;
    padding-right: 3em;
    height: 100px;
    max-height: 100px;
    position: sticky;
    top: 0;
    z-index: 200;
    display: flex;

    .nav-logo-container {
        width: 10%;
        max-width: 160px;
        display: flex;
        align-items: center;
    }

    .nav-links-container {
        width: 75%;
        max-width: 1300px;
        display: flex;
        align-items: center;

        a {
            margin-left: 2em;
            color: colors.$primary-black;
            font-size: 18px;
            font-weight: 500;
            font-family: factor-a-medium, 'arial';
            position: relative;
        }

        a::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 1px;
            bottom: -3px;
            background-color: colors.$primary-black;
            transform: scaleX(0);
            transition: transform 500ms ease;
        }

        a:hover::before {
            transform: scaleX(1);
        }

        a:active {
            color: colors.$primary-black;
        }

        .underline {
            text-decoration: underline;
            text-underline-offset: 6px;
            text-decoration-thickness: 1px;

        }

        .underline::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 1px;
            bottom: -3px;
            background-color: colors.$primary-black;
            transform: scaleX(0);
            transition: transform 500ms ease;
        }

        .underline:hover::before {
            transform: scaleX(0);
        }

    }

    .nav-button-soumission {
        width: 20%;
        max-width: 250px;
        display: flex;
        align-items: center;
    }

    .ham-menu {
        display: none;
    }

}


@media (max-width: 1200px) {

    .main-container-navbar {
        background-color: colors.$secondary-light-purple;

        .nav-button-soumission {
            display: none;
        }

        .nav-button-rdv {
            display: none;
        }

        .nav-links-container {
            margin-right: 0;
            display: none;
        }

        .nav-logo-container {
            min-width: 200px;
            padding-left: 1.8em;
        }

        .ham-menu {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            padding-right: 1.8em;
        }

    }

    .ham-container {
        display: var(--showHamMenu);
        background-color: colors.$secondary-light-purple;
        position: absolute;
        z-index: 4;
        height: 100vh;
        width: 100%;
        padding-top: 3em;

        .ham-nav-links-container {
            display: flex;
            flex-direction: column;
            padding-left: 5em;

            a {
                font-size: 50px;
                line-height: 85px;
                font-family: factor-a-medium, 'arial';
                position: relative;
            }
        
            a::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 1px;
                bottom: -3px;
                background-color: colors.$primary-black;
                transform: scaleX(0);
                transition: transform 500ms ease;
            }
        
            a:hover::before {
                transform: scaleX(1);
            }
        
            a:active {
                color: colors.$primary-black;
            }
        
            .underline {
                text-decoration: underline;
                text-underline-offset: 6px;
                text-decoration-thickness: 1px;
        
            }
        
            .underline::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 1px;
                bottom: -3px;
                background-color: colors.$primary-black;
                transform: scaleX(0);
                transition: transform 500ms ease;
            }
        
            .underline:hover::before {
                transform: scaleX(0);
            }

            .particuliers {
                max-width: 5.5em;
            }

            .entreprises {
                max-width: 5.5em;
            }

            .reclamations {
                max-width: 6.5em;
            }

            .carrieres {
                max-width: 4.5em;
            }

            .nous-joindre {
                max-width: 6.1em;
            }
        }

        .ham-nav-button-container-rdv {
            margin-bottom: 1em;

            a {
                text-decoration: none;
            }
        }

    }
}


@media (max-width: breakpoints.$breakpoint-mobile) {

    .main-container-navbar {
        padding-left: 1.5em;
        padding-right: 1.5em;

        .nav-logo-container {
            padding-left: 0;
        }

        .ham-menu {
            padding-right: 0;
        }

    }

    .ham-container {

        .ham-nav-links-container {
            padding-left: 1.5em;
            margin-bottom: 3em;

            a {
                font-size: 35px;
                line-height: 70px;
            }

        }
    }
}