@use '../breakpoints';
@use '../colors';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section#second-form-particulier {
    user-select: none;
    height: 800px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;

}