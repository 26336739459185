@use '../breakpoints';

#carrieres-form{
  background-color: white;
  z-index: 99999999;
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  left: unset;
  overflow-y: auto;
  transform: translate(100%);
  width: 0;


  &.hide{
    width: 55%;
    animation: hide 0.8s ease both;
    @keyframes hide{
      0%{transform: translate(0);}
      100%{transform: translate(100%);}
    }

  }

  &.show{
    width: 55%;
    animation: show 0.8s ease forwards;
    border-left: 1.5px solid black;

    @keyframes show{
      0%{transform: translate(100%);}
      100%{transform: translate(0);}
    }
  }


  > :nth-child(2){
    margin: auto;
    width: 90%;
  }


  h2 {
    font-size: 3em;
    text-align: left;


    margin-bottom: 0.5em;
    span {
      opacity: 0.5;
    }
  }

  .cross{
    position: absolute;
    right: 0;
    cursor: pointer;
    padding: 1em;



    &:hover{
      opacity: 0.5;
    }
  }

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1em;

      .col {
        min-height: 120px;
        width: 48%;
      }
    }

  .send-zone{
    display: flex;
    justify-content: flex-end;

    .button{
      min-width: 200px;
      width: 20%;
    }
  }

}


@media (max-width: breakpoints.$breakpoint-mobile) {
  #carrieres-form {

    &.hide{
      width: 100%;
      animation: hide 0.8s ease both;
      @keyframes hide{
        0%{transform: translate(0);}
        100%{transform: translate(100%);}
      }

    }

    &.show{
      width: 100%;
      animation: show 0.8s ease forwards;
      @keyframes show{
        0%{transform: translate(100%);}
        100%{transform: translate(0);}
      }
    }


    > :nth-child(2){
      margin: auto;
      width: 100%;
    }

    h2{
      font-size: 2em;
      width: 90%;
    }

    .row {
      flex-wrap: wrap;

      .col {
        width: 100%;
      }
    }
  }
}
