@use '../breakpoints';
@use '../colors';

section#soumission {
    width: 100%;
    height: 100%;
    padding-right: 5em;
    padding-left: 5em;
    padding-top: 3em;
    display: flex;
    flex-wrap: wrap;
    background-color: colors.$primary-white;

    .top {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        align-content: center;
        margin-bottom: 50px;

        .title {
            width: 55%;
            height: 170px;
            margin-top: 50px;
            padding-left: 50px;
            display: flex;
            align-items: center;
            
            h1{
                font-size: 60px;
                font-family: factor-a-regular, arial;
            }
        }

        .avatar-speech {
            width: 45%;
            height: 170px;
            margin-top: 50px;
            padding-left: 100px;
            display: flex;
            align-items: center;

            .speech-bubble {
                width: 100%;
                height: 120px;
                font-size: 20px;
                border-radius: 15px;
                display: flex;
                position: relative;
                align-items: center;
                align-content: flex-start;
                background-color: colors.$secondary-light-green;

                p {
                    font-size: 21px;
                    margin-left: 20px;
                    line-height: 30px;
                    font-family: factor-a-regular;
                    color: colors.$primary-black;
                }
            }

            .speech-bubble:after {
                width: 0;
                top: 50%;
                z-index: 1;
                right: -21px;
                margin-top: -10px;
                border-width: 10px 0 10px 21px;
                content: '';
                display: block;
                position: absolute;
                border-style: solid;
                border-color: transparent colors.$secondary-light-green;
            }
            
            .avatar {
                width: 50%;
                height: 170px;
                display: flex;
                align-items: center;
                align-content: center;
                padding-left: 30px;

                img {
                    width: 170px;
                    height: auto;
                }
            }

            
        }

    }

    .bottom {
        width: 100%;
        // height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;

        .date-picker {
            width: 50%;
            height: 290px;
            padding-left: 50px;

            .date-picker-label{
                width: 60%;
                padding-bottom: 15px;
                p{
                    min-height: 40px;
                    font-size: 18px;
                    font-family: factor-a-bold;
                    color: colors.$primary-black;
        
                    .required{
                        color:#f47628;
                    }
                }
            }
        }
        

        .rdv-form {
            width: 50%;
            height: 100%;
            padding-left: 40px;
            padding-right: 40px;
            display: flex;
            flex-direction: column;

            .row1 {
                width: 100%;
                height: 120px;
                display: flex;

                .left-form {
                    padding-right: 30px;
                    width: 50%;
                }

                .right-form {
                    padding-left: 30px;
                    width: 50%;
                }
            }

            .row2 {
                width: 100%;
                height: 120px;
            }

            .row3 {
                width: 100%;
                height: 120px;                
            }

            .check-zone {
                width: 100%;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .send-zone {
        width: 100%;
        height: 170px;
        padding-top: 50px;
        display: flex;
        justify-content: center;
        
        .back-button{
            height: 60px;
            width: 135px;
            padding-top: 25px;
            margin-right: 30px;
            display: flex;
            cursor: pointer;
            position: relative;

            .back-arrow{
                display: flex;
                flex-direction: row;

                .arrow{
                    display: flex;
                    // border-bottom: colors.$primary-black 2px solid;
    
                    img{
                        height: 22px;
                        margin-right: 10px;
                        rotate: -45deg;
                    };
                }
    
                h1{
                    font-size: 20px;
                    font-family: factor-a-regular, arial;
                    // border-bottom: colors.$primary-black 2px solid;
                }
            }

            .back-arrow:hover {
                color: #000;
            }
    
            .back-arrow::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 2px;
                bottom: -10px;
                left: 0;
                background-color: #000;
                transform: scaleX(1);
                transition: transform 550ms ease;
            }
    
            .back-arrow:hover::before {
                transform: scaleX(0);
            }

        }
        
        

        .button {
            width: 12%;
        }
    }

}


@media (max-width: breakpoints.$breakpoint-tablet) {

    section#soumission {
        height: 100%;
        padding-right: 0;
        padding-left: 0;
    
        .top {
            width: 100%;
            flex-wrap: wrap;
            padding-right: 1.5em;
            padding-left: 1.5em;
            margin-bottom: 20px;

            .title {
                width: 100%;
                height: 90px;
                padding-left: 0;
                margin-bottom: 10px;
                
                h1{
                    font-size: 40px;
                    font-family: factor-a-regular, arial;
                }
            }
    
            .avatar-speech {
                width: 100%;
                height: 120px;
                margin-top: 0;
                padding-left: 0;
    
                .speech-bubble {
                    width: 70%;
                    height: 90px;
    
                    p {
                        font-size: 20px;
                        line-height: 25px;
                        margin-left: 15px;
                    }
                }
              
                .avatar {
                    width: 30%;
                    height: 170px;
                    padding-left: 30px;
    
                    img {
                        width: 100%;
                    }
                }
            }
        }
    
        .bottom {
            padding-right: 1.5em;
            padding-left: 1.5em;

            .date-picker {
                order: 0;
                width: 70%;
                padding-left: 0;                
            }
            
            .rdv-form {
                order: 1;
                width: 100%;
                height: 100%;//
                margin-top: 0;
                padding-left: 0;
                padding-right: 0;
                padding-top: 2em;
                
                .row1 {
                    width: 100%;
                    height: 220px;
                    flex-wrap: wrap;
    
                    .left-form {
                        padding-right: 0;
                        width: 100%;
                    }
    
                    .right-form {
                        padding-left: 0;
                        width: 100%;
                    }
                }
    
                .row2 {
                    height: 110px;
                }
            }
        }
    
        .send-zone {
            width: 100%;
            height: 170px;
            padding-top: 40px;
            border-bottom: colors.$primary-black 1.5px solid;
         
            .button {
                width: 40%;
            }
        }
    }

}
  

@media (max-width: breakpoints.$breakpoint-mobile) {

    section#soumission {
        height: 100%;
        padding-right: 0;
        padding-left: 0;
        padding-top: 0;
        
    
        .top {
            width: 100%;
            flex-wrap: wrap;
            padding-right: 1.5em;
            padding-left: 1.5em;
            margin-bottom: 20px;

            .title {
                width: 100%;
                height: 90px;
                padding-left: 0;
                margin-bottom: 10px;
                
                h1{
                    font-size: 36px;
                    font-family: factor-a-regular, arial;
                }
            }
    
            .avatar-speech {
                width: 100%;
                height: 120px;
                margin-top: 0;
                padding-left: 0;
    
                .speech-bubble {
                    width: 60%;
                    height: 90px;
    
                    p {
                        width: 90%;
                        font-size: 13px;
                        line-height: 20px;
                        margin-left: 15px;
                    }
                }
              
                .avatar {
                    width: 40%;
                    height: 170px;
                    padding-left: 30px;
    
                    img {
                        width: 100%;
                    }
                }
            }
        }
    
        .bottom {
            padding-right: 1.5em;
            padding-left: 1.5em;

            .date-picker {
                order: 0;//
                width: 100%;
                padding-left: 0;

                .date-picker-label{
                    width: 100%; //
                    padding-bottom: 15px;
                    p{
                        min-height: 40px;
                        font-size: 18px;
                        font-family: factor-a-bold;
                        color: colors.$primary-black;
            
                        .required{
                            color:#f47628;
                        }
                    }
                }
            }
            
    
            .rdv-form {
                order: 1;
                width: 100%;
                height: 100%;//
                margin-top: 70px; //
                padding-left: 0;
                padding-right: 0;
    
                .row1 {
                    width: 100%;
                    height: 220px;
                    flex-wrap: wrap;
    
                    .left-form {
                        padding-right: 0;
                        width: 100%;
                    }
    
                    .right-form {
                        padding-left: 0;
                        width: 100%;
                    }
                }
    
                .row2 {
                    height: 110px;
                }
                .check-zone {
                    margin-top: 40px;
                }
            }
        }
    
        .send-zone {
            height: 250px;
            width: 100%;
            padding-top: 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            border-bottom: colors.$primary-black 1.5px solid;

            .back-button{
                order: 1;
                width: 35%; ///
                margin-right: 0;
            }

            .button {
                width: 65%;
                order:0;
            }
        }
    }
}