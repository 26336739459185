@use '../breakpoints';
@use '../colors';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section#main-banner-particulier {
    height: 800px;
    display: flex;

    .image-container-banner-particulier {
        width: 40%;
        display: block;
        border-bottom: solid 1px colors.$primary-black ;
        margin-right: 5em;
        
        .image-banner-particulier {
            width: 450px;
            margin-left: auto;
            margin-right: auto;
            padding-top: 9em;

        }
    }

    .title-container-banner-particulier {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: solid 1px colors.$primary-black ;
        margin-left: 5em;
        padding-left: 3em;

        .subtile {
            font-family: factor-a-medium, arial;
            font-size: 18px;
            letter-spacing: 0.05em;
            margin-bottom: 2em;
        }

        .title {
            font-size: 85px;
            letter-spacing: 0.025em;
            font-feature-settings: "ss01";
            font-family: factor-a-light, 'arial';
        }

        .anchors {
            width: 100%;
            cursor: pointer;
            margin-top: 3.5em;

            .second_achor {
                margin-left: 3.5em;
            }

            a {
                color: #000;
                font-size: 22px;
                position: relative;
                text-decoration: none;
                font-family: factor-a-medium;
            }

            a:hover {
                color: #000;
            }

            a::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 2px;
                bottom: -10px;
                left: 0;
                background-color: #000;
                transform: scaleX(1);
                transition: transform 550ms ease; ///
            }

            a:hover::before {
                transform: scaleX(0);
            }

        }

    }
}

@media screen and (max-width: 1350px) {
    section#main-banner-particulier {

        .title-container-banner-particulier {

            .subtile {
                font-size: 14.4px;
            }
            .title {
                font-size: 68px;
            }

            .anchors {

                a {
                    font-size: 17.6px;
                }
            }
        }

        .image-container-banner-particulier {
            

            .image-banner-particulier {
                padding-top: 12em;
                width: 360px;
            }
        }
    }
}

@media screen and (max-width: 950px) {
    section#main-banner-particulier {
        max-height: 700px;

        .title-container-banner-particulier {
            margin-left: 2em;
            margin-right: 2em;
            width: 100%;
            padding-left: 2em;

            .subtile {
                font-size: 14.4px;
            }
            .title {
                font-size: 68px;
            }

            .anchors {

                a {
                    font-size: 17.6px;
                }
            }
        }

        .image-container-banner-particulier {
            display: none;
            
        }
    }
}

@media screen and (max-width: 765px) {
    section#main-banner-particulier {
        max-height: 1000px;
        flex-direction: column;

        .title-container-banner-particulier {
            border-bottom: none;
            padding-left: 1.5em;
            margin: 0;
            min-height: 400px;

            .subtile {
                font-size: 16px;
   
            }
            .title {
                font-size: 42px;
                margin-bottom: 0;
                padding-bottom: 0;
   
            }

            .anchors {
                margin-top: 2em;

                .first_achor {
                    margin-bottom: 2em;
                    max-width: 215px;
                }

                .second_achor {
                    margin-left: 0;
                    max-width: 165px
                }

                a {
                    display: flex;
                    font-size: 17px;  
                }
            }
        }

        .image-container-banner-particulier {
            display: flex;
            width: 100%;
            border-bottom: none;

            .image-banner-particulier {
                width: 350px;
                padding-top: 0;
                padding-bottom: 2em;
                border-bottom: 1px solid colors.$primary-black;
            }
        }
    }
}



// @media screen and (max-width: 375px) {
    
//     section#main-banner-particulier {

//         .image-container-banner-particulier {
            
//             .image-banner-particulier {
//                 width: 325px;
//             }
//         }

//     }
// }