@use '../breakpoints';
@use '../colors';


section#localisation {
    min-height: 750px;
    display: flex;
    background-color: colors.$primary-white;

    .left-container {
        width: 60%;
        border-right: 1px solid colors.$primary-black;
        border-bottom: 1px solid colors.$primary-black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 5em;
        padding-left: 2em;

        .left-container-title {
            font-family: factor-a-regular, "Arial";
            font-feature-settings: "ss01";
            font-weight: 500;
            font-size: 60px;
            line-height: 100px;
        }

        .left-container-links {
            display: flex;
            margin-top: 3em;

            a {
                color: #000;
                position: relative;
                text-decoration: none;
                font-size: 1.5em;
                font-family: factor-a-medium;
            }
        
            a:hover {
                color: #000;
            }
        
            a::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 2px;
                bottom: -10px;
                left: 0;
                background-color: #000;
                transform: scaleX(1);
                transition: transform 550ms ease;
            }
        
            a:hover::before {
                transform: scaleX(0);
            }

            .left-container-links-rdv {
                margin-right: 3em;

            }
        }

    }

    .right-container {
        width: 40%;
        margin-right: 5em;
        border-bottom: 1px solid colors.$primary-black;

        .right-container-office {
            height: 35%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid colors.$primary-black;

            .right-container-office-title {
                width: 100%;
                padding-left: 3em;

                h1 {
                    font-family: factor-a-medium;
                    font-size: 2em;
                    padding-bottom: 0.75em;
                }

                p {
                    font-family: factor-a-regular;
                    padding-bottom: 0.5em;
                    font-size: 1.2em;
                }
            }

            .right-container-office-link {
                height: 57%;
                display: flex;
                align-items: flex-start;

            }
        }

        .right-container-ref {
            height: 15%;
            display: flex;
            align-items: center;
            padding-left: 3em;

            h1 {
                width: 100%;
                font-family: factor-a-medium;
                font-size: 1.8em;
            }

        }

        .bottom {
            border-bottom: 1px solid colors.$primary-black;
        }

    }
}



@media screen and (max-width: 1150px) {
    section#localisation {

        .left-container {

            .left-container-title {
                font-size: 50px;
                line-height: 75px;
            }

            .left-container-links {

                a {
                    font-size: 1.1em;
                }
            }
        }

        .right-container {

            .right-container-ref {

                h1 {
                    font-size: 1.5em;
                }
            }
        }
    }
}

@media screen and (max-width: 935px) {
    section#localisation {
        flex-direction: column;
        border-bottom: solid 1px colors.$primary-black;

        .left-container {
            min-height: 400px;
            border-right: none;
            margin-left: 0;
            padding-left: 20px;
            padding-top: 50px;
            width: 100%;

            .left-container-title {
                font-size: 37px;
                line-height: 58px;

            }

            .left-container-links {
                flex-direction: column;
                padding-bottom: 4em;
                font-size: 20px;

                .left-container-links-rdv {
                    padding-bottom: 2em;

                }

            }
        }

        .right-container {
            min-height: 500px;
            width: 90%;
            margin-left: 20px;
            padding-left: 0;
            border-bottom: none;

            .right-container-office {
                width: 100%;
                min-height: 150px;
    

                .right-container-office-title {
                    padding-left: 0;
                    font-size: 12px;
                }

                .right-container-office-link {
                    margin-bottom: 3.3em;
                }

            }

            .right-container-ref {
                padding-left: 0;
                min-height: 85px;
                border-bottom: none;
            }

            .bottom {
                border-bottom: solid 1px colors.$primary-black;
            }
        }
    }
}