@use '../breakpoints';
@use '../colors';

section#confirmation {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: colors.$primary-white;


    .left-confirmation {
        width: 55%;
        height: 600px;
        padding-left: 5em;
        padding-right: 5em;
        padding-top: 8em;
        display: flex;
        flex-direction: column;
        align-content: center;
        flex-wrap: wrap;
        border-right: colors.$primary-black 1.5px solid;


        .slogan {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-left: 3em;

            // background-color: tomato;

            h1 {
                font-size: 60px;
                line-height: 70px;
                letter-spacing: 1px;
            }

            p{
                padding-top: 20px;
                font-size: 17px;
                font-family: factor-a-regular, arial;

                span{
                    font-family: factor-a-bold, arial;
                }
            }
        }

        .button-zone{
            padding-top: 2em;
            padding-left: 3em;

            width: 38%;
            text-decoration: none;

        }
    }

    .right-confirmation {
        width: 45%;
        height: 600px;
        padding-top: 10em;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;

        .slogan{
            width: 50%;
            padding-bottom: 2em;
            text-align: center;


            h1{
                font-size: 60px;
                line-height: 70px;
                letter-spacing: 1px;
                font-family: factor-a-regular, arial;
            }
        }

                        
        a.line {

            cursor: pointer;
            color: #000;
            position: relative;
            text-decoration: none;
            font-size: 1.2em;
            font-family: factor-a-medium;
        }
        
        a.line:hover {
            color: #000;
        }

        a.line::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            bottom: -10px;
            left: 0;
            background-color: #000;
            transform: scaleX(1);
            transition: transform 550ms ease;
            
        }

        a.line:hover::before {
            transform: scaleX(0);
        }

        .button-zone {
            display: none;
        }

    }

    .button-tablet-zone{
        display: none;

        .button-tablet{
            display: none;
        }
    }

}

section#confirmationconcours {
    width: 60%;
    height: 830px;
    padding-top: 6em;
    padding-left: 8em;
    padding-right: 8em;
    overflow-y: scroll;



    .left-confirmation {
        width: 100%;
        height: 600px;
        padding-left: 5em;
        padding-right: 5em;
        padding-top: 8em;
        display: flex;
        flex-direction: column;
        align-content: center;
        flex-wrap: wrap;

        .slogan {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-left: 3em;

            h1 {
                font-size: 50px;
                line-height: 60px;
                letter-spacing: 1px;
            }

            p{
                padding-top: 20px;
                font-size: 17px;
                font-family: factor-a-regular, arial;

                span{
                    font-family: factor-a-bold, arial;
                }
            }
        }
    }
}



@media (max-width: breakpoints.$breakpoint-tablet) {

    section#confirmation {

        .left-confirmation {
            width: 50%; //
            height: 300px; //
            padding-left: 1.5em; //
            padding-right: 1.5em; //
            padding-top: 5em; //
            border-right: 0; //
            text-align: center; //
            justify-content: center;
            border-right: colors.$primary-black 1.5px solid; //

            .slogan {
                padding-left: 0;
                padding-bottom: 2em;

                h1 {
                    font-size: 45px; //
                    line-height: 55px; //
                }
    
                p{
                    font-size: 15px; //
                    padding-top: 30px; //
                    padding-bottom: 30px; //
                }
            }
    
            .button-zone{
                display: none;    
            }
        }
    
        .right-confirmation {
            width: 50%; //
            height: 300px; //
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border-bottom: 0; //
            padding-top: 0;

            .slogan{
                padding-bottom: 30px;
                width: 80%;
    
                h1{
                    font-size: 45px; //
                    line-height: 50px; //
                }
            }

            a.line {
                font-size: 1em; //
            }
                
            a {
                font-size: 1em; //
            }   
            
            .button-zone{
                display: none; //
            }
        }

        .button-tablet-zone{
            width: 100%;
            height: 120px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            flex-wrap: wrap;
            border-bottom: colors.$primary-black 1.5px solid;

            .button-tablet{
                display: flex;
                width: 40%;
            }
        }
    }

    section#confirmationconcours {
        width: 55%;
        padding-left: 1.5em;
        padding-right: 1.5em;

        .left-confirmation {
            padding-left: 0;
            padding-right: 0;
            padding-top: 4em;

    
            .slogan {
                padding-left: 1em;
            }
        }
    }
}
  

@media (max-width: breakpoints.$breakpoint-mobile) {

    section#confirmation {

        .left-confirmation {
            width: 100%; //
            height: 250px; //
            padding-left: 1.5em; //
            padding-right: 1.5em; //
            border-right: 0; //
            text-align: center; //
            justify-content: none;

            .slogan {
                border-bottom: colors.$primary-black 1.5px solid; //
                padding-left: 0;
    
                h1 {
                    font-size: 45px; //
                    line-height: 55px; //
                }
    
                p{
                    font-size: 15px; //
                    padding-top: 30px; //
                    padding-bottom: 30px; //
                }
            }
    
            .button-zone{
                display: none;    
            }
        }
    
        .right-confirmation {
            width: 100%; //
            height: 400px; //
            border-bottom: colors.$primary-black 1.5px solid; //
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding-top: 2em;

            .slogan{
                padding-bottom: 2em;//
                width: 60%;
    
                h1{
                    font-size: 40px; //
                    line-height: 50px; //
                }
            }

            a.line {
                font-size: 1em; //
            }
                
            a {
                font-size: 1em; //
            }   
            
            .button-zone{
                width: 50%;
                border-bottom: 0;
                padding-top: 3em;//
                padding-bottom: 0;//
                display: block; //
                text-decoration: none;

    
            }
        }

        .button-tablet-zone{
            display: none;

            .button-tablet{
                display: none;
            }
        }
    }

    section#confirmationconcours {
        width: 100%;

        .left-confirmation {
            padding-left: 0;
            padding-right: 0;
            padding-top: 4em;

    
            .slogan {
                padding-left: 1em;
            }
        }
    }
}