@use '../breakpoints';
@use '../colors';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section#main-banner-entreprise {
    height: 800px;
    display: flex;


    .image-container-banner-entreprise {
        width: 40%;
        display: block;
        margin-right: 5em;
        border-bottom: 1px solid colors.$primary-black;

        .image-banner-entreprise {
            width: 400px;
            margin-left: auto;
            margin-right: auto;
            padding-top: 9em;
        }
    }

    .title-container-banner-entreprise {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: solid 1px colors.$primary-black ;
        margin-left: 5em;
        padding-left: 3em;
        

        .subtile {
            font-family: factor-a-medium, arial;
            font-size: 18px;
            letter-spacing: 0.05em;
            margin-bottom: 2em;
        }

        .title {
            font-size: 85px;
            letter-spacing: 0.025em;
            font-feature-settings: "ss01";
            font-family: factor-a-light, 'arial';
        }

        .anchors {
            width: 100%;
            cursor: pointer;
            margin-top: 3.5em;

            .second_achor {
                margin-left: 3.5em;
            }

            a {
                color: #000;
                font-size: 22px;
                position: relative;
                text-decoration: none;
                font-family: factor-a-medium;
            }

            a:hover {
                color: #000;
            }

            a::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 2px;
                bottom: -10px;
                left: 0;
                background-color: #000;
                transform: scaleX(1);
                transition: transform 550ms ease; ///
            }

            a:hover::before {
                transform: scaleX(0);
            }

        }
    }
    
}

@media screen and (max-width: 1225px) {
    section#main-banner-entreprise {

        .title-container-banner-entreprise {

            .subtile {
                font-size: 14.4px;
            }
            .title {
                font-size: 68px;
            }

            .anchors {

                a {
                    font-size: 17.6px;
                }
            }
        }

        .image-container-banner-entreprise {

            .image-banner-entreprise {
                width: 320px;
                padding-top: 12em;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    section#main-banner-entreprise {
        max-height: 600px;


        .title-container-banner-entreprise {
            width: 100%;
            margin-left: 3em;
            margin-right: 3em;
            padding-left: 0;

            .subtile {
                font-size: 18px;
            }
            .title {
                font-size: 85px;
            }

            .anchors {

                a {
                    font-size: 20px;
                }
            }
        }

        .image-container-banner-entreprise {
            display: none;
        }
    }
}

@media screen and (max-width: 765px) {
    section#main-banner-entreprise {
        max-height: 1000px;
        flex-direction: column;

        .title-container-banner-entreprise {
            padding-left: 1.5em;
            margin-left: 0;
            min-height: 400px;
            border-bottom: none;

            .subtile {
                font-size: 16px;
            }
            .title {
                font-size: 42px;

            }

            .anchors {
                margin-top: 2.5em;

                .first_achor {
                    margin-bottom: 2em;
                    max-width: 215px;
                }

                .second_achor {
                    margin-left: 0;
                    max-width: 165px;
                }

                a {
                    display: flex;
                    font-size: 17px;
                }
            }
        }

        .image-container-banner-entreprise {
            display: flex;
            width: 100%;
            border-bottom: none;

            .image-banner-entreprise {
                width: 340px;
                margin-left: auto;
                margin-right: auto;
                padding-bottom: 2em;
                padding-top: 0;
                border-bottom: 1px solid colors.$primary-black;
            }
        }
    }
}

