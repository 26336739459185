@use '../breakpoints';
@use '../colors';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.date-picker-container {
    width: 100%;
    height: 100%;
    max-width: 620px;
    max-height: 400px;
    border: colors.$primary-black 2px solid;
    display: flex;

    .disable {
        opacity: 0.2;
        &:active {
            background-color: unset !important;
            font-family: factor-a-light;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
        }
    }

    .dates {
        width: 70%;
        font-family: factor-a-light;

        .months {
            height: 25%;
            border-bottom: colors.$primary-black 2px solid;

            .months-selection {
                font-family: factor-a-medium;
                font-size: 1.2em;
                height: 60%;
                display: flex;
                justify-content: space-evenly;
                align-items: center;

                // .left-arrow {
                //     // background-color: red;
                // }

                .left-arrow:hover {
                    cursor: pointer;
                }

                // .current-month {
                //     // background-color: red;
                // }

                // .right-arrow {
                //     // background-color: red;
                // }

                .right-arrow:hover {
                    cursor: pointer;
                }

            }

            .week-days-selection {
                height: 40%;

                .week-days-selection-list {
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    list-style-type: none;
                }
            }
        }

        .dates-selection {
            height: 75%;
            display: grid;
            grid-template-columns: repeat(7, 1fr);

            .day {
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: space-around;
            }

            .weekend-day {
                color: grey;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
            }


            .day:active,:focus {
                font-family: factor-a-bold;
                background-color: colors.$secondary-light-green;
            }

        }
    }

    .hours {
        width: 30%;

        .title {
            border-left: colors.$primary-black 2px solid;
            border-bottom: colors.$primary-black 2px solid;
            font-size: .75em;
            height: 25%;
            text-align: center;
            display: flex;
            justify-content: center;

            .title-hours {
                font-family: factor-a-bold;
                font-size: 1.55em;

                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .hours-details {
            font-family: factor-a-light;
            border-left: colors.$primary-black 2px solid;
            height: 75%;
            text-align: center;

            .hours-ul {
                list-style-type: none;
                height: 100%;
                overflow-x: hidden;
                overflow-y: scroll;
            }

            .hour {
                padding: 0.8em;
            }

            .hour:hover {
                cursor: pointer;
            }


            .hour:active {
                font-family: factor-a-medium;
                background-color: colors.$secondary-light-green;
            }
        }
    }

    .selected {
        background-color: colors.$secondary-light-green;
        font-family: factor-a-bold;
    }
}


@media (max-width: breakpoints.$breakpoint-tablet) {

    .date-picker-container {
        max-width: 620px;
        max-height: 400px;
    }
}


@media (max-width: breakpoints.$breakpoint-mobile) {

    .date-picker-container {
        max-width: 520px;
        max-height: 300px;
    }
}
