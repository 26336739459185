
.main-container-nous-joindre {
    position: relative;
    z-index: 1;
    background: white;
    box-shadow: 0px 15px 10px -15px #111;
}

.main-footer-nous-joindre {
    position: sticky;
    bottom: 0;
}