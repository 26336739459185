@use '../colors';
@use '../breakpoints';

@keyframes show-social {
  0% {
    height: 0;
  }
  100% {
    height: 70px;
  }
}

#carrieres-fiche {
  display: flex;

  height: 100%;
  flex-direction: row;
  padding-bottom: 1em;
  font-family: factor-a-bold;



  section {
    width: 50%;
    padding: 1em;
    margin: 0 2em;
    padding-top: 50px !important;
    &:nth-of-type(1){
      border-top: unset;
    }
  }

  section#carrieres-fiche-title {
    border-right: 1px solid black;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 4em;
      margin-top: 2em;
    }

    .link{
      &::after {
        margin-top: 10px;
      }
      > span {
        width: 90%;
      }
      img{
        width: 40px;
        height: 20px;
        padding-right: 0.3em;
        margin-right: 20px;
        display: inline-block;
      }
    }

    h2{
      font-family: factor-a-light;
      font-feature-settings: "ss01";
      margin-top: 15vh;
      font-size: 5em;
      text-align: left;
    }

    .main-button {
      width: 200px;
      margin: 0;
      margin-right: 2em;

    }
  }

  section#carrieres-fiche-description{
    border-top: none;
    height: 90vh;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }

    .carrieres-fiche-infos{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1em;
      padding-bottom: 1em;
      border-bottom: 1px solid black;
      padding-bottom: 2em;

      .right-div {
        padding-left: 4em;
      }

      div {
        width: 40%;
        margin: 0.5em;

        h3{
          opacity: 0.5;
          font-size: 1.5em;
          margin-bottom: 0.2em;
          font-family: factor-a-medium;
        }
        p{
          font-family: factor-a-medium;
          font-size: 1.5em;
        }

        span{
          font-size: 15px;
        }

      }
    }

    .desc {
      text-align: justify;
      line-height: 1.5em; // Test pag

      img {
        margin-right: 0;
      }
      
      h3 {
        font-size: 2em;
        font-family: factor-a-bold;
        margin-bottom: 1em;
      }

      p {
        font-family: neue-haas-unica-pro;
        font-size: 1.1em; // Test pag

      }

      ul {
        text-indent: 15px;
        margin: 0.5em;
      }
      ul.dashed {
        list-style-type: none;
        list-style-position: inside;

      }
      ul.dashed > li {
        font-family: factor-a-regular;
        line-height: 1.5em; // Test pag
        font-size: 1.1em; // Test pag

        text-indent: -1em;
        padding-left: 1em;

      }
      ul.dashed > li:before {
        content: "-";
        text-indent: -5px;
      }
    }
  }

  .share-button{
    position: relative;

    svg {
      height: 28px;
      width: 31px;
    }

    .social-network{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      padding-left: 0.1em;
      background-repeat: no-repeat;
      background-image: url('../../../public/img/carrieres/rectangle.svg');
      position: absolute;
      left: -78px;
      overflow: hidden;
      bottom: -80px;
      width: 0;
      height:0;
      margin: 0;
      padding: 0;

      .clickboard {
        padding-left: 0;
      }

      img {
        padding-left: 0.4em;
        padding-top: 0.25em;

        &:hover {
          opacity: 0.5;
        }
      }

      &.open{
        width: 222px;
        animation: show-social 0.3s ease forwards;
      }

      a{
        position: relative;
        &:nth-of-type(3){
          span {
            display: block;
            position: absolute;
            width: 100px;
            height: 100px;
          }
        }
      }

    }
  }
}





@media (max-width: breakpoints.$breakpoint-tablet) {
  #carrieres-fiche {

    section#carrieres-fiche-title{
      .link {
        font-size: 1.2em;
      }
    }

    .share-button{
      .social-network{
        background-size: 200px;
        &.open{
          width: 205px;
        }
      }

    }

    section {
      width: 100%;

    }
  }
}

@media (max-width: breakpoints.$breakpoint-mobile) {
  #carrieres-fiche {
    flex-wrap: wrap;

    section#carrieres-fiche-title{
      .link {
        font-size: 1.5em;
      }
    }


    section {
      &:nth-of-type(1){
        border-bottom: 1px solid black;
      }
    }
    section#carrieres-fiche-title{
      border-right: unset;
      margin: 0;
      padding-left: 1em;
      font-size: 0.7em;

      h2 {
        margin-top: 1em;
        width: 100%;
      }


    }

    section#carrieres-fiche-description{
      margin: 0.5em;
      padding-top: 1.5em;
      height: 100%;

      .carrieres-fiche-infos {
        div {
          width: 100%;
        }

        .right-div {
          padding-left: 0;
        }
      }
    }

    section {
      width: 100%;
    }
  }

}


@media (max-width: breakpoints.$breakpoint-small) {

  #carrieres-fiche {

    section#carrieres-fiche-title {
      .link {
        font-size: 1.1em;
      }
    }
  }

  }
