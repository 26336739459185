@use '../breakpoints';
@use '../colors';


.main-button {
    width: 100%;
    height: 70px;
    display: flex;
    align-content:center;
    align-items:center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    border: colors.$primary-black 1px solid;
    box-shadow: rgb(255, 255, 255) 5px 5px 0px -1px, rgb(0, 0, 0) 5px 5px;
    background-color: colors.$primary-black;


    h1 {
        margin-left: 1.5em;
        margin-right: 1.5em;
        font-size: 1.2em;
        letter-spacing: 1px;
        display: block;
        font-weight: bold;
        text-align: center;
        font-family: factor-a-medium, arial;
        color: colors.$primary-white;
    }

    &:active, &:hover {
        box-shadow: none;
        background-color: colors.$secondary-light-green;

        h1 {
            color: colors.$primary-black;
        }
    }


}
.share-button{
    border: 2px dotted black;
    border-radius: 60%;
    padding: 18px;
    cursor: pointer;
    background-color: black;

    path {
        fill: black;
    }

  &:hover {
    fill: white;
  }

    &.open {
        animation: pop 0.2s ease forwards;
        path {
            fill: white;
        }
    }

@keyframes pop{
    100%{background-color: colors.$secondary-light-green;}
}

@keyframes close{
    100%{background-color: black;}
}

}
.main-button-inversed {


  background-color: colors.$secondary-light-green;

  h1 {
      color: colors.$primary-black;
  }

  &:active, &:hover {
    background-color: colors.$primary-black;

    path {
      fill: white;
    }

    h1 {
      color: colors.$primary-white;
    }
  }
}


.main-button-inversed-nav-mobile {
  background-color: colors.$secondary-light-green;
  max-width: 55%;

  h1 {
    color: colors.$primary-black;
  }

  &:active,
  &:hover {
    background-color: colors.$primary-black;

    path {
      fill: white;
    }

    h1 {
      color: colors.$primary-white;
    }
  }

}

.disabled-button {
    background-color: #878787;
    pointer-events: none;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
 .dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: colors.$primary-black;
  color: colors.$primary-black;
  box-shadow: 9999px 0 0 -5px colors.$primary-white;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: colors.$primary-white;
  color: colors.$primary-white;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px colors.$primary-white;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px colors.$primary-white;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px colors.$primary-white;
  }
  30% {
    box-shadow: 9984px 0 0 2px colors.$primary-white;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px colors.$primary-white;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px colors.$primary-white;
  }
  30% {
    box-shadow: 9999px 0 0 2px colors.$primary-white;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px colors.$primary-white;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px colors.$primary-white;
  }
  30% {
    box-shadow: 10014px 0 0 2px colors.$primary-white;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px colors.$primary-white;
  }
}



@media (max-width: breakpoints.$breakpoint-tablet) {

    .generate-button{
        width: 80%;
        height: 60px;
    }

    .back-button{
        display: flex;

        button {
            margin-left: 2rem;
            width: 55px;
            height: 55px;

            img {
                width: 60%;
            }
        }

        button:hover {
            img {
                width: 60%;
            }
        }
    }


}


@media (max-width: breakpoints.$breakpoint-mobile) {


    .generate-button{
        width: 100%;
        height: 60px;
    }

    .back-button{
        display: flex;

        button {
            margin-left: 2rem;
            width: 55px;
            height: 55px;

            img {
                width: 60%;
            }
        }

        button:hover {
            img {
                width: 60%;
            }
        }
    }

}
